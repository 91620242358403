import { useOutletContext } from "react-router-dom";
import { BookingRequest } from "../../../../../services/bookingRequest/bookingRequestModel";
import { Campaign } from "../../../../../services/campaign/campaignModel";
import { compareDate, useSearch } from "../../../../../react-helpers/array";
import { useMemo } from "react";
import { isAfter, isBefore, parseISO } from "date-fns";
import BookingsTable from "./BookingsTable";

const CampaignBookingsSection = () => {
  const { campaign, bookingRequests: initialCampaignBookingRequests } =
    useOutletContext<{
      campaign: Campaign;
      bookingRequests: BookingRequest[];
    }>();

  const [bookingRequests, setSearch] = useSearch(
    initialCampaignBookingRequests,
    (br) => [br.extEmail, br.extLastname, br.extFirstname],
  );

  const futureBookings = useMemo(() => {
    return bookingRequests
      .filter(
        (bookingRequest) =>
          bookingRequest.booking?.startTime &&
          isBefore(new Date(), parseISO(bookingRequest.booking.startTime)),
      )
      .sort((a, b) => compareDate(a.booking!.startTime, b.booking!.startTime));
  }, [bookingRequests]);

  const ongoingBookings = useMemo(() => {
    return bookingRequests.filter(
      (bookingRequest) =>
        bookingRequest.booking?.startTime &&
        isAfter(new Date(), parseISO(bookingRequest.booking.startTime)) &&
        bookingRequest.booking.endTime &&
        isBefore(new Date(), parseISO(bookingRequest.booking.endTime)),
    );
  }, [bookingRequests]);

  const bookingsToClose = useMemo(() => {
    return bookingRequests
      .filter(
        (bookingRequest) =>
          !!bookingRequest.booking?.endTime &&
          !bookingRequest.senderClosureDate &&
          isAfter(new Date(), parseISO(bookingRequest.booking.endTime)),
      )
      .sort((a, b) => compareDate(b.booking!.startTime, a.booking!.startTime));
  }, [bookingRequests]);

  const closedBookings = useMemo(() => {
    return bookingRequests
      .filter(
        (bookingRequest) =>
          !!bookingRequest.booking?.endTime &&
          !!bookingRequest.senderClosureDate &&
          isAfter(new Date(), parseISO(bookingRequest.booking.endTime)),
      )
      .sort((a, b) => compareDate(b.booking!.startTime, a.booking!.startTime));
  }, [bookingRequests]);

  return (
    <div className="lblocks">
      {initialCampaignBookingRequests.length > 0 && (
        <div className="lblocks">
          <div className="lrow --bottom">
            <div className="--stretched">
              <input
                className="input"
                onChange={(ev) => setSearch(ev.target.value)}
                placeholder="Rechercher un rendez-vous"
              />
            </div>
          </div>
        </div>
      )}

      {bookingRequests.length === 0 &&
        initialCampaignBookingRequests.length > 0 && (
          <div className="info">
            Aucun rendez-vous ne correspond à ces critères de recherche
          </div>
        )}

      {ongoingBookings.length > 0 && (
        <div className="lblocks">
          <h2 className="section_title">Vos rendez-vous en cours</h2>
          <BookingsTable
            campaign={campaign}
            showVisioLink
            bookingRequests={ongoingBookings}
            date="start"
          />
        </div>
      )}
      {futureBookings.length > 0 && (
        <div className="lblocks">
          <h2 className="section_title">Vos rendez-vous à venir</h2>
          <BookingsTable
            campaign={campaign}
            showVisioLink
            bookingRequests={futureBookings}
            date="start"
          />
        </div>
      )}
      {bookingsToClose.length > 0 && (
        <div className="lblocks">
          <h2 className="section_title">Vos rendez-vous à clôturer</h2>
          <BookingsTable
            campaign={campaign}
            bookingRequests={bookingsToClose}
            date="end"
          />
        </div>
      )}
      {closedBookings.length > 0 && (
        <div className="lblocks">
          <h2 className="section_title">Vos rendez-vous terminés</h2>
          <BookingsTable
            campaign={campaign}
            bookingRequests={closedBookings}
            date="end"
          />
        </div>
      )}
    </div>
  );
};

export default CampaignBookingsSection;
