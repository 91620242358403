import { baseAPI } from "../api";
import { Plan } from "../businessAccount/businessAccountModel";
import {
  Association,
  CompanyActivity,
  CompanyHeadcount,
  ConfigData,
  DeclineReason,
  Expertise,
  HierarchyLevel,
  Language,
  Skill,
} from "./dataModel";

export function getSkillsRequest(): Promise<Skill[]> {
  return baseAPI.get("/data/skills").then(({ data }) => data);
}

export function createSkillRequest(skill: Skill): Promise<Skill> {
  return baseAPI.post("/data/skills", skill).then(({ data }) => data);
}

export function getAssociationsRequest(): Promise<Association[]> {
  return baseAPI.get("/data/associations").then(({ data }) => data);
}

export function getPublicAssociationsRequest(): Promise<Association[]> {
  return baseAPI.get("/data/associations/public").then(({ data }) => data);
}

export function createAssociationRequest(
  association: Association,
): Promise<Association> {
  return baseAPI
    .post("/data/associations", association)
    .then(({ data }) => data);
}

export function getExpertisesRequest(): Promise<Expertise[]> {
  return baseAPI.get("/data/expertises").then(({ data }) => data);
}

export function getCompanyActivitiesRequest(): Promise<CompanyActivity[]> {
  return baseAPI.get("/data/company-activities").then(({ data }) => data);
}

export function getCompanyHeadcountsRequest(): Promise<CompanyHeadcount[]> {
  return baseAPI.get("/data/company-headcounts").then(({ data }) => data);
}

export function getHierarchyLevelsRequest(): Promise<HierarchyLevel[]> {
  return baseAPI.get("/data/hierarchy-levels").then(({ data }) => data);
}

export function getLanguagesRequest(): Promise<Language[]> {
  return baseAPI.get("/data/languages").then(({ data }) => data);
}

export function getDeclineReasonsRequest(): Promise<DeclineReason[]> {
  return baseAPI.get("/data/decline-reasons").then(({ data }) => data);
}

export function getAllConfigDataRequest(): Promise<ConfigData> {
  return baseAPI.get("/data/config").then(({ data }) => data);
}

export function getPlansRequest(): Promise<Plan[]> {
  return baseAPI.get("/data/plans").then(({ data }) => data);
}
