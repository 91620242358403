import {
  Link,
  Outlet,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import {
  Campaign,
  CampaignContactStatus,
} from "../../../../../services/campaign/campaignModel";
import { orderByField, useSearch } from "../../../../../react-helpers/array";
import { ClientOnly } from "../../../../../react-helpers/react";
import { useMemo, useState } from "react";
import { downloadFile } from "../../../../../react-helpers/files";
import { useTranslation } from "react-i18next";
import {
  campaignService,
  mapBookingRequestToContactStatus,
} from "../../../../../services/campaign/campaignService";
import Icon from "../../../../../components/Icon";
import CopyToClipboard from "../../../../../components/utilities/CopyToClipboard";
import slugify from "slugify";
import { formatDate } from "../../../../../react-helpers/date";
import SubmitButton from "../../../../../components/forms/SubmitButton";
import { useConfirmationWithIntl } from "../../../../../components/ConfirmationDialog";
import useReload from "../../../../../hooks/useReload";
import { Tooltip } from "react-tooltip";

const ContactsListTab = ({ archived }: { archived?: boolean }) => {
  const { t } = useTranslation(["campaign"]);
  const { campaign } = useOutletContext<{
    campaign: Campaign;
  }>();
  const [searchParams] = useSearchParams();
  const { confirm } = useConfirmationWithIntl(["campaign"]);
  const { archiveCampaignBookingRequest, unarchiveCampaignBookingRequest } =
    campaignService();
  const reload = useReload();

  const [filterByStatus, setFilterByStatus] =
    useState<CampaignContactStatus | null>(null);

  const filteredBookingRequests = useMemo(() => {
    return campaign.bookingRequests!.filter((br) =>
      archived
        ? br.campaignArchivedDate !== null
        : br.campaignArchivedDate === null,
    );
  }, [archived, campaign.bookingRequests]);

  const sortedContacts = useMemo(() => {
    return filteredBookingRequests.sort(orderByField("extEmail"));
  }, [filteredBookingRequests]);

  const [searchedContacts, setSearch] = useSearch(
    sortedContacts,
    (bookingRequest) => [
      bookingRequest.extFirstname,
      bookingRequest.extLastname,
      bookingRequest.extEmail,
    ],
  );
  const filteredContacts = useMemo(
    () =>
      searchedContacts.filter(
        (contact) =>
          !filterByStatus ||
          mapBookingRequestToContactStatus(contact) === filterByStatus,
      ),
    [searchedContacts, filterByStatus],
  );

  const csv = useMemo(() => {
    let csvContent = "firstname;lastname;email;status;campaignlink\r\n";
    sortedContacts.forEach((bookingRequest) => {
      csvContent += `"${bookingRequest.extFirstname}";"${bookingRequest.extLastname}";"${bookingRequest.extEmail}";${mapBookingRequestToContactStatus(bookingRequest)};${import.meta.env.SSR ? null : location.origin}/ext/${bookingRequest.uuid}\r\n`;
    });
    return new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  }, [sortedContacts]);

  return (
    <div className="lblocks">
      {searchParams.get("import") && campaign.type === "TARGETED" && (
        <div className="info">
          Voici le lien individuel généré pour chacun de vos contacts.
          <br />
          Il vous suffit de copier/coller chaque lien individuel et l'envoyer
          par email ou via LinkedIn pour solliciter vos interlocuteurs.
        </div>
      )}
      {((campaign.bookingRequests!.length > 0 && !archived) ||
        filteredBookingRequests.length > 0) && (
        <div className="lblocks">
          <div className="lrow --bottom">
            <div className="--stretched">
              <input
                className="input"
                onChange={(ev) => setSearch(ev.target.value)}
                placeholder="Rechercher un contact"
              />
            </div>

            <div>
              <label className="field-label">Filter par statut</label>
              <select
                className="select"
                onChange={(ev) =>
                  setFilterByStatus(
                    ev.target.value.length > 0
                      ? (ev.target.value as CampaignContactStatus)
                      : null,
                  )
                }
              >
                <option value="">Tous</option>
                <option value="WAITING">
                  {t("campaign:contact-status.WAITING")}
                </option>
                <option value="SENT">
                  {t("campaign:contact-status.SENT")}
                </option>
                <option value="ACCEPTED">
                  {t("campaign:contact-status.ACCEPTED")}
                </option>
                <option value="DECLINED">
                  {t("campaign:contact-status.DECLINED")}
                </option>
              </select>
            </div>
          </div>

          <div className="cblocks --s">
            <div className="--txt--right">
              <Link className="btn--soft --xs" to={`import`}>
                <Icon name="plus" />
                Importer des contacts
              </Link>
              <button
                className="btn--soft --xs"
                type="button"
                onClick={() =>
                  downloadFile(
                    csv,
                    `contacts_${slugify(campaign.name, {
                      locale: "en",
                      trim: true,
                      lower: true,
                      strict: true,
                    })}_${formatDate(new Date(), "yyyy-MM-dd")}.csv`,
                  )
                }
              >
                Exporter la liste (CSV)
                <Icon name="download" />
              </button>
            </div>
            <div className="table-scroll">
              <table className="table">
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Prénom</th>
                    <th>Nom</th>
                    {!archived &&
                      ["TARGETED", "MANAGED"].includes(
                        campaign.type as string,
                      ) && <th>Lien de réservation</th>}
                    <th>Statut</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {filteredContacts.map((bookingRequest) => (
                    <tr key={bookingRequest.id}>
                      <td>
                        <strong>{bookingRequest.extEmail}</strong>
                      </td>
                      <td>{bookingRequest.extFirstname}</td>
                      <td>{bookingRequest.extLastname}</td>
                      {!archived &&
                        ["TARGETED", "MANAGED"].includes(
                          campaign.type as string,
                        ) && (
                          <td>
                            <ClientOnly>
                              {() => (
                                <CopyToClipboard
                                  value={`${location.origin}/ext/${bookingRequest.uuid}`}
                                />
                              )}
                            </ClientOnly>
                          </td>
                        )}
                      <td className="--shrink">
                        <div className="ui-row --s --nowrap">
                          <span className="chip">
                            {t(
                              `campaign:contact-status.${mapBookingRequestToContactStatus(bookingRequest)}`,
                            )}
                          </span>
                          {archived && bookingRequest.campaignArchivedDate && (
                            <span className="chip --error">Archivé</span>
                          )}
                        </div>
                      </td>
                      <td className="--shrink --txt-right">
                        {archived && (
                          <SubmitButton
                            data-tooltip-id="br-unarchive-tooltip"
                            type="button"
                            className="btn--success --btn--icon --unarchive"
                            onClick={async () => {
                              await unarchiveCampaignBookingRequest(
                                campaign.id,
                                bookingRequest.id,
                              );
                              reload();
                            }}
                          />
                        )}
                        {!archived &&
                          mapBookingRequestToContactStatus(bookingRequest) !==
                            "ACCEPTED" &&
                          bookingRequest.campaignArchivedDate === null && (
                            <SubmitButton
                              data-tooltip-id="br-archive-tooltip"
                              type="button"
                              className="btn--warning --btn--icon --archive"
                              onClick={() =>
                                confirm(
                                  "campaign:archive-booking-requests.CONFIRM",
                                  async () => {
                                    await archiveCampaignBookingRequest(
                                      campaign.id,
                                      bookingRequest.id,
                                    );
                                    reload();
                                  },
                                  true,
                                  {
                                    confirmText: t(
                                      "campaign:archive-booking-requests.SUBMIT",
                                    ),
                                    cancelText: t(
                                      "campaign:archive-booking-requests.CANCEL",
                                    ),
                                  },
                                )
                              }
                            />
                          )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {campaign.bookingRequests!.length === 0 && (
        <div className="info">
          <div className="cblocks">
            <div>Cette campagne ne contient encore aucun contact</div>
            <div>
              <Link to="import" className="btn --s">
                + Importer mes 1ers contacts
              </Link>
            </div>
          </div>
        </div>
      )}
      {campaign.bookingRequests!.length > 0 &&
        archived &&
        filteredBookingRequests.length === 0 && (
          <div className="info">
            Aucun contact archivé
            <br />
            <Link to=".." className="link --xs --with-icon">
              <Icon name="chevron-back" />
              Retour aux contacts
            </Link>
          </div>
        )}
      <Outlet context={{ campaign }} />
      <Tooltip id="br-archive-tooltip" content="archiver" />
      <Tooltip id="br-unarchive-tooltip" content="désarchiver" />
    </div>
  );
};

export default ContactsListTab;
