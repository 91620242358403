import { useCallback, useMemo, useState } from "react";
import { AnySchema, object, string } from "yup";
import { campaignService } from "../../../../../../services/campaign/campaignService";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import { Campaign } from "../../../../../../services/campaign/campaignModel";
import { toastsWithIntl } from "../../../../../../services/toastService";
import { User } from "../../../../../../services/user/userModel";
import useReload from "../../../../../../hooks/useReload";
import { randomUUID } from "../../../../../../react-helpers/crypto";
import Dialog from "../../../../../../components/Dialog";

const { addContactsToCampaign } = campaignService();

export type ImportedUser = Pick<User, "firstname" | "lastname" | "email"> & {
  id: string;
};

export interface ContactsOutletContext {
  campaign: Campaign;
  contacts: ImportedUser[];
  setContacts: (prev: (prev: ImportedUser[]) => ImportedUser[]) => void;
  schema: AnySchema;
  validContacts: ImportedUser[];
  addValidContacts: () => Promise<void>;
}

const schema = object({
  firstname: string().label("Prénom").required(),
  lastname: string().label("Nom").required(),
  email: string().label("Email").email().required(),
});

const ContactsImportDialog = () => {
  const { campaign } = useOutletContext<{
    campaign: Campaign;
  }>();

  const { toastSuccess, toastError } = toastsWithIntl(["campaign"]);
  const navigate = useNavigate();
  const reload = useReload();

  const [contacts, setContacts] = useState<
    (Pick<User, "firstname" | "lastname" | "email"> & { id: string })[]
  >([{ id: randomUUID() }, { id: randomUUID() }, { id: randomUUID() }]);

  const validContacts = useMemo(
    () => contacts.filter((contact) => schema.isValidSync(contact)),
    [contacts],
  );

  const addValidContacts = useCallback(() => {
    return addContactsToCampaign(
      campaign.id,
      validContacts,
      campaign.type === "MANAGED" ? "send" : "dontSend",
    ).then(
      () => {
        toastSuccess("campaign:contacts-import.SUCCESS");
        reload();
        navigate("./..?import=1");
      },
      (err) => {
        switch (err?.response?.status) {
          case 402:
            toastError("campaign:contacts-import.ERROR_NO_CARD");
            break;
          case 412:
            toastError("campaign:contacts-import.ERROR_CARD_EXPIRED");
            break;
          default:
            toastError("campaign:contacts-import.ERROR");
        }
      },
    );
  }, [campaign, validContacts, toastSuccess, toastError, navigate, reload]);

  return (
    <Dialog className="--drawer" onClose={() => navigate("..")}>
      <>
        <div className="popup_head">
          <div className="popup_title">Importer des contacts</div>
        </div>
        <div className="popup_body lcol --stretch --stretched">
          <Outlet
            context={
              {
                addValidContacts,
                validContacts,
                campaign,
                schema,
                setContacts,
                contacts,
              } as ContactsOutletContext
            }
          />
        </div>
      </>
    </Dialog>
  );
};

export default ContactsImportDialog;
