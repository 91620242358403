import { Outlet, useLoaderData } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import { Campaign } from "../../../../../services/campaign/campaignModel";
import { BusinessAccount } from "../../../../../services/businessAccount/businessAccountModel";

const CampaignBookingsPage = () => {
  const { campaign, businessAccount } = useLoaderData() as {
    campaign: Campaign;
    businessAccount: BusinessAccount;
  };

  const initialCampaignBookingRequests = campaign.bookingRequests ?? [];

  return (
    <div className="container">
      <div className="page_head">
        <div>
          <BackButton
            forcedUrl={`/app/pro/${businessAccount.id}/campaigns/${campaign.id}`}
          />
          <h1 className="page_title">Les rendez-vous de votre campagne</h1>
        </div>
      </div>
      {/* {initialCampaignBookingRequests.length > 0 && (
        <Tabs>
          <NavLink className="tab_item" to="." end>
            Rendez-vous
          </NavLink>
          <NavLink className="tab_item" to="archived">
            Rendez-vous archivés
          </NavLink>
        </Tabs>
      )} */}
      <div className="page-content lblocks">
        {initialCampaignBookingRequests.length === 0 ? (
          <div className="info">
            Vous n'avez encore aucun rendez-vous dans le cadre de cette
            campagne.
          </div>
        ) : (
          <Outlet
            context={{
              campaign,
              bookingRequests: initialCampaignBookingRequests,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default CampaignBookingsPage;
