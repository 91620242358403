import { useMemo, useState } from "react";
import SxFileInput from "../../../forms/fields/SxFileInput";
import SxError from "../../../forms/SxError";
import SxField from "../../../forms/fields/SxField";
import { Association } from "../../../services/data/dataModel";
import { number, object, string } from "yup";
import {
  checkIban,
  file,
  transformEmptyToNull,
  transformEmptyToUndefined,
} from "../../../react-helpers/yup";

export const useExtClosureBankingDetails = ({
  associationId,
  forcedProbono,
}: {
  associationId?: number | null;
  forcedProbono?: boolean;
}) => {
  const [probono, setProbono] = useState(true);

  const bankingDetailsSchema = useMemo(
    () =>
      object(
        probono
          ? {
              recipientAssociationId: number()
                .label("Don à l’association")
                .nullable()
                .required()
                .disabled(!!associationId),
              otherAssociationName: string()
                .label("Autre association")
                .transform(transformEmptyToNull)
                .nullable()
                .when("recipientAssociationId", {
                  is: (field: string | number) => String(field) === "-1",
                  then: (schema) => schema.required(),
                  otherwise: (schema) => schema.notVisible(),
                }),
            }
          : {
              extIban: string()
                .label("IBAN")
                .transform(transformEmptyToUndefined)
                .test("checkIbanFormat", "IBAN invalide", checkIban)
                .required(),
              extBic: string()
                .label("BIC")
                .transform(transformEmptyToUndefined)
                .required(),
              extBankDetails: file().label("Fichier de coordonnées").required(),
            },
      ),
    [probono, associationId],
  );

  const ExtClosureBankingDetails = ({
    associations,
  }: {
    associations: Association[];
  }) => {
    return (
      <>
        {!forcedProbono && (
          <select
            className="select"
            value={String(probono)}
            onChange={(e) => setProbono(e.target.value === "true")}
          >
            <option value="true">
              Je choisis de reverser ma rémunération à une association
            </option>
            <option value="false">
              Je souhaite recevoir ma rémunération directement sur le RIB de mon
              choix
            </option>
          </select>
        )}

        {probono ? (
          <>
            <SxField
              as="autocomplete"
              name="recipientAssociationId"
              options={[
                ...associations.map((a) => ({
                  label: a.labelFr!,
                  value: String(a.id),
                })),
                {
                  label: "Autre",
                  value: "-1",
                },
              ]}
            />
            <SxField as="text" name="otherAssociationName" />
          </>
        ) : (
          <>
            <div className="grid">
              <SxField name="extIban" />
              <SxField name="extBic" />
            </div>

            <SxFileInput name="extBankDetails" accept="application/pdf,image/*">
              {(files: (File | string)[]) => (
                <>
                  {files.map((file) => (
                    <p key={typeof file === "string" ? file : file.name}>
                      {typeof file === "string" ? file : file.name}
                    </p>
                  ))}
                </>
              )}
            </SxFileInput>
            <SxError name="extBankDetails" />
          </>
        )}
      </>
    );
  };

  return {
    probono,
    bankingDetailsSchema,
    ExtClosureBankingDetails,
    bankingDetailsInitialValues: {
      extIban: "",
      extBic: "",
      extBankDetails: "",
      recipientAssociationId: associationId ?? null,
      otherAssociationName: null,
    },
  };
};
