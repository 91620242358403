import { useOutletContext } from "react-router-dom";
import {
  AppointmentsStatus,
  BookingRequest,
} from "../../../services/bookingRequest/bookingRequestModel";
import { useCallback, useMemo } from "react";
import {
  compareDate,
  orderByField,
  useSearch,
} from "../../../react-helpers/array";
import AppointmentsTable from "../../../components/appointments/AppointmentTable";
import { User } from "../../../services/user/userModel";

const AppointmentsSection = () => {
  const { paidAndBookedBookingRequests, loggedUserId } = useOutletContext<{
    paidAndBookedBookingRequests: BookingRequest[];
    loggedUserId: User["id"];
  }>();

  const compareBooking = orderByField<BookingRequest, "booking">(
    "booking",
    "desc",
    (a, b) => compareDate(new Date(a!.startTime), new Date(b!.startTime)),
  );

  const isSender = useCallback(
    (request: BookingRequest) => request.sender!.user.id === loggedUserId,
    [loggedUserId],
  );

  const [searchedBookingRequests, setSearch] = useSearch(
    paidAndBookedBookingRequests,
    (br) => [
      br.campaign?.name,
      br.extLastname,
      br.extFirstname,
      br.sender?.user.displayName,
      br.recipient?.user.displayName,
    ],
  );

  // an appointment is to come if the start date is in the future
  const appointmentToCome = useMemo(
    () =>
      searchedBookingRequests
        .filter((request) => new Date(request.booking!.startTime) > new Date())
        .sort(compareBooking),
    [searchedBookingRequests, compareBooking],
  );

  // an appointment is ongoing if the start date is in the past and the end date is in the future
  const onGoingAppointment = useMemo(
    () =>
      searchedBookingRequests
        .filter(
          (request) =>
            new Date(request.booking!.startTime) < new Date() &&
            new Date(request.booking!.endTime) > new Date(),
        )
        .sort(compareBooking),
    [searchedBookingRequests, compareBooking],
  );

  // an appointment is to close if the request is in past and the current user has not closed
  const appointmentToClose = useMemo(
    () =>
      searchedBookingRequests
        .filter(
          (request) =>
            new Date(request.booking!.endTime) < new Date() &&
            !["CLOSED", "CONFLICT"].includes(request.status!) &&
            (isSender(request)
              ? request.senderClosureDate === null
              : request.recipientClosureDate === null),
        )
        .sort(compareBooking),
    [searchedBookingRequests, compareBooking, isSender],
  );

  // an appointment is closed if the request status is "CLOSED"|"CONFLICT" or the current user has closed
  const appointmentClosed = useMemo(
    () =>
      searchedBookingRequests
        .filter(
          (request) =>
            ["CLOSED", "CONFLICT"].includes(request.status!) ||
            (isSender(request)
              ? request.senderClosureDate !== null
              : request.recipientClosureDate !== null),
        )
        .sort(compareBooking),
    [searchedBookingRequests, compareBooking, isSender],
  );

  return (
    <div>
      {paidAndBookedBookingRequests.length === 0 ? (
        <div className="info">Aucun RDV pour l'instant</div>
      ) : (
        <>
          <div className="lblock --stretched">
            <input
              className="input"
              onChange={(ev) => setSearch(ev.target.value)}
              placeholder="Rechercher un rendez-vous"
            />
          </div>
          <div className="lblock">
            {appointmentToCome.length === 0 &&
              onGoingAppointment.length === 0 &&
              appointmentToClose.length === 0 &&
              appointmentClosed.length === 0 && (
                <div className="info">
                  Aucun rendez-vous ne correspond à ces critères de recherche
                </div>
              )}
            {appointmentToCome.length > 0 && (
              <AppointmentsTable
                bookingRequests={appointmentToCome}
                loggedUserId={loggedUserId}
                status={AppointmentsStatus.TO_COME}
              />
            )}
            {onGoingAppointment.length > 0 && (
              <AppointmentsTable
                bookingRequests={onGoingAppointment}
                loggedUserId={loggedUserId}
                status={AppointmentsStatus.ONGOING}
              />
            )}
            {appointmentToClose.length > 0 && (
              <AppointmentsTable
                bookingRequests={appointmentToClose}
                loggedUserId={loggedUserId}
                status={AppointmentsStatus.TO_CLOSE}
              />
            )}
            {appointmentClosed.length > 0 && (
              <AppointmentsTable
                bookingRequests={appointmentClosed}
                loggedUserId={loggedUserId}
                status={AppointmentsStatus.CLOSED}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default AppointmentsSection;
