import { Link, useLoaderData } from "react-router-dom";
import { Campaign } from "../../../services/campaign/campaignModel";
import { authService } from "../../../services/auth/authService";
import { useMemo, useState } from "react";
import CampaignDialog from "./CampaignDialog";
import { User } from "../../../services/user/userModel";
import { BookingRequest } from "../../../services/bookingRequest/bookingRequestModel";
import SubmitButton from "../../../components/forms/SubmitButton";
import { bookingRequestService } from "../../../services/bookingRequest/bookingRequestService";
import RejectedDialog from "./RejectedDialog";
import { DeclineReason } from "../../../services/data/dataModel";
import ProfilePic from "../../../components/profileDisplay/ProfilePic";
import useReload from "../../../hooks/useReload";

const { isLoggedIn } = authService();
const { updateBookingRequestById } = bookingRequestService();

const SollicitationPage = () => {
  const { campaign, currentUser, bookingRequest, declineReasons } =
    useLoaderData() as {
      campaign: Campaign;
      currentUser?: User;
      bookingRequest: BookingRequest;
      declineReasons: DeclineReason[];
    };
  const reload = useReload();

  const [showCampaignDialog, setShowCampaignDialog] = useState(false);
  const [showRejectedDialog, setShowRejectedDialog] = useState(false);

  const isLogged = isLoggedIn();

  const bookingsCount = useMemo(
    () =>
      campaign.bookingRequests?.filter(
        (bookingRequest) => !!bookingRequest.booking,
      ).length,
    [campaign],
  );

  const [isClosed, isSuspended, isFull, isExpired, isArchived] = useMemo(
    () => [
      campaign.status === "CLOSED",
      campaign.status === "SUSPENDED",
      !!campaign.maxVisio && (bookingsCount ?? 0) >= campaign.maxVisio,
      !!campaign.endDate && new Date() > new Date(campaign.endDate),
      bookingRequest.campaignArchivedDate !== null,
    ],
    [campaign, bookingsCount, bookingRequest.campaignArchivedDate],
  );
  const hasIssue = useMemo(
    () => isClosed || isSuspended || isFull || isExpired || isArchived,
    [isClosed, isSuspended, isFull, isExpired, isArchived],
  );

  return (
    // TODO : (REFACTO) mutualize with campaignPage
    <div className="home">
      <div className="home_head">
        <div className="container">
          <h1 className="title--70 --txt--fluo--green">Consultation Tisio</h1>
          <h2 className="title lblock">
            Bonjour {bookingRequest.extFirstname} {bookingRequest.extLastname},
          </h2>
          <h2 className="cblock--s body --strong">
            {bookingRequest.sender?.user.displayName}{" "}
            {campaign.businessAccount?.name &&
              `(${campaign.businessAccount.name})`}{" "}
            souhaite vous interviewer
          </h2>
        </div>
      </div>
      <div className="page-content container --wrap">
        {hasIssue && (
          <div className="lblocks">
            <div className="info --warning">
              {isClosed || isExpired || isSuspended || isArchived
                ? "Cette consultation est désormais terminée, vous ne pouvez plus vous inscrire."
                : isFull &&
                  "Cette consultation est désormais terminée car le nombre d’interviews souhaité a été atteint. Il n'est plus possible de vous inscrire."}
            </div>
            {!isLogged && (
              <div className="card --bg">
                <div className="card_body --txt--center cblocks --s">
                  <div>
                    <Link to="/register" className="btn--2">
                      CRÉEZ VOTRE PROFIL TISIO
                    </Link>
                  </div>
                  <div className="body--30">
                    pour recevoir d’autres opportunités comme celle-ci
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {!hasIssue && (
          <div className="--txt--center lblocks">
            <div className="cblocks">
              <div className="lrow --center">
                <ProfilePic src={bookingRequest.sender?.user.profilePicture} />
                <div className="--txt--left">
                  <h2 className="page_title">Votre interlocuteur</h2>
                  <div className="section_title">
                    {bookingRequest.sender?.user.displayName}{" "}
                    {campaign.businessAccount?.name &&
                      `(${campaign.businessAccount.name})`}
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card_body cblocks">
                <h2 className="page_title">
                  Contexte et objet de cette consultation
                </h2>
                <pre className="body">{campaign.context}</pre>
              </div>
            </div>
            <div className="card --bg">
              <div className="card_body cblocks">
                <div>
                  <h2 className="page_title">
                    {campaign.probonoCampaign
                      ? `Somme versée à l'association ${campaign.association?.labelFr ?? "de votre choix"}`
                      : "Rémunération proposée"}
                  </h2>
                  <p>Pour une interview de 30 minutes en visio</p>
                </div>

                <div className="title--70 --txt--blue">
                  {campaign.price! / 100}€
                </div>

                <div>
                  {bookingRequest.status === "ACCEPTED" && (
                    <div className="info lblocks">
                      <div className="--txt--center --block">
                        Vous avez accepté cette proposition, mais pas encore
                        pris rendez-vous
                      </div>
                      <div className="--txt--center --block">
                        <button
                          className="btn--2"
                          type="button"
                          onClick={() => setShowCampaignDialog(true)}
                        >
                          Choisir la date de l'interview
                        </button>
                      </div>
                    </div>
                  )}
                  {bookingRequest.status === "PAID" ? (
                    <div className="ui-row --center">
                      <SubmitButton
                        type="button"
                        className="btn"
                        onClick={async () => {
                          await updateBookingRequestById(
                            isLogged ? bookingRequest.id : bookingRequest.uuid,
                            {
                              ...bookingRequest,
                              status: "ACCEPTED",
                            },
                          ).catch((err) => {
                            reload();
                            return Promise.reject(err);
                          });
                          setShowCampaignDialog(true);
                        }}
                      >
                        J'accepte l'interview
                      </SubmitButton>
                      <SubmitButton
                        type="button"
                        className="btn--grey"
                        onClick={async () => {
                          await updateBookingRequestById(
                            isLogged ? bookingRequest.id : bookingRequest.uuid,
                            {
                              ...bookingRequest,
                              status: "DECLINED",
                            },
                          ).catch((err) => {
                            reload();
                            return Promise.reject(err);
                          });
                          setShowRejectedDialog(true);
                        }}
                      >
                        Je décline
                      </SubmitButton>
                    </div>
                  ) : (
                    bookingRequest.status !== "ACCEPTED" && (
                      <div className="info">
                        Vous avez déjà{" "}
                        {bookingRequest.status === "DECLINED"
                          ? "refusé"
                          : "accepté"}{" "}
                        cette proposition
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {showCampaignDialog && (
        <CampaignDialog
          campaign={campaign}
          currentUser={currentUser}
          initialBookingRequest={bookingRequest}
          onClose={() => setShowCampaignDialog(false)}
        />
      )}
      {showRejectedDialog && (
        <RejectedDialog
          declineReasons={declineReasons}
          bookingRequest={bookingRequest}
          onClose={() => setShowRejectedDialog(false)}
        />
      )}
    </div>
  );
};

export default SollicitationPage;
