import Icon from "../../../components/Icon";

function HomeWhyTisioSection() {
  return (
    <div className="section --light --txt--center">
      <div className="container lblocks --xl">
        <h2 className="section_title">Pourquoi utiliser Tisio ?</h2>
        <div className="lblock flex-grid --3 --center--h --stretch--v">
          <div className="card --shadowed">
            <div className="card_body --txt--center">
              <Icon name="lightbulb" className="--l --blue" />
              <div className="paragraph_title">Conseil</div>
              <div className="cblock">
                Recueillir les conseils pratiques d’un pair pour mieux
                comprendre un sujet opérationnel précis.
              </div>
            </div>
          </div>
          <div className="card --shadowed">
            <div className="card_body --txt--center">
              <Icon name="prospect" className="--l --blue" />
              <div className="paragraph_title">Prospection</div>
              <div className="cblock">
                Contacter des prospects pour proposer son offre et/ou recueillir
                leur feedback.
              </div>
              <a
                href="https://www.tisio.fr/business/prospection-commerciale"
                className="btn--3 --block lblock"
              >
                En savoir plus
              </a>
            </div>
          </div>
          <div className="card --shadowed">
            <div className="card_body --txt--center">
              <Icon name="expert" className="--l --blue" />
              <div className="paragraph_title">Expertise</div>
              <div className="cblock">
                Échanger avec un expert pour s’acculturer sur une thématique
                avant une décision stratégique.
              </div>
            </div>
          </div>
          <div className="card --shadowed">
            <div className="card_body --txt--center">
              <Icon name="reconversion" className="--l --blue" />
              <div className="paragraph_title">Enquêtes qualitatives</div>
              <div className="cblock">
                Consulter ses clients pour avoir des retours qualitatifs
              </div>
            </div>
          </div>
          <div className="card --shadowed">
            <div className="card_body --txt--center">
              <Icon name="advice" className="--l --blue" />
              <div className="paragraph_title">Avis</div>
              <div className="cblock">
                Solliciter un avis désintéressé sur un projet avant de le
                lancer.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeWhyTisioSection;
