import { Link, useLoaderData } from "react-router-dom";
import { Campaign } from "../../../services/campaign/campaignModel";
import { authService } from "../../../services/auth/authService";
import { useMemo, useState } from "react";
import CampaignDialog from "./CampaignDialog";
import { User } from "../../../services/user/userModel";
import ProfilePic from "../../../components/profileDisplay/ProfilePic";

const { isLoggedIn } = authService();

const CampaignPage = () => {
  const { campaign, currentUser } = useLoaderData() as {
    campaign: Campaign;
    currentUser?: User;
  };

  const [showCampaignDialog, setShowCampaignDialog] = useState(false);

  const isLogged = isLoggedIn();

  const bookingsCount = useMemo(
    () =>
      campaign.bookingRequests?.filter(
        (bookingRequest) => !!bookingRequest.booking,
      ).length,
    [campaign],
  );

  const [isClosed, isSuspended, isFull, isExpired] = useMemo(
    () => [
      campaign.status === "CLOSED",
      campaign.status === "SUSPENDED",
      !!campaign.maxVisio && (bookingsCount ?? 0) >= campaign.maxVisio,
      !!campaign.endDate && new Date() > new Date(campaign.endDate),
    ],
    [campaign, bookingsCount],
  );
  const hasIssue = useMemo(
    () => isClosed || isSuspended || isFull || isExpired,
    [isClosed, isSuspended, isFull, isExpired],
  );

  const senderDisplay = useMemo(
    () =>
      campaign.referent?.displayName +
      " " +
      (campaign.businessAccount?.name && `(${campaign.businessAccount.name})`),
    [campaign.businessAccount?.name, campaign.referent?.displayName],
  );

  return (
    <div className="home">
      <div className="home_head">
        <div className="container">
          <h1 className="title--70 --txt--fluo--green">Consultation Tisio</h1>
          <h2 className="title--30">Interview en visio avec {senderDisplay}</h2>
        </div>
      </div>
      <div className="container --wrap">
        <div className="page-content">
          {hasIssue && (
            <div className="lblocks">
              <div className="info --warning">
                {isClosed || isExpired || isSuspended
                  ? "Cette consultation est désormais terminée, vous ne pouvez plus vous inscrire."
                  : isFull &&
                    "Cette consultation est désormais terminée car le nombre d’interviews souhaité a été atteint. Il n'est plus possible de vous inscrire."}
              </div>
              {!isLogged && (
                <div className="card --bg">
                  <div className="card_body --txt--center cblocks --s">
                    <div>
                      <Link to="/register" className="btn--2">
                        CRÉEZ VOTRE PROFIL TISIO
                      </Link>
                    </div>
                    <div className="body--30">
                      pour recevoir d’autres opportunités comme celle-ci
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {!hasIssue && (
            <div className="--txt--center lblocks">
              <div className="cblocks">
                <div className="lrow --center">
                  <ProfilePic src={campaign.referent?.profilePicture} />
                  <div className="--txt--left">
                    <h2 className="page_title">Votre interlocuteur</h2>
                    <div className="section_title">{senderDisplay}</div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card_body cblocks">
                  <h2 className="page_title">
                    Contexte et objet de cette consultation
                  </h2>
                  <pre className="body">{campaign.context}</pre>
                </div>
              </div>

              <div className="card --bg">
                <div className="card_body cblocks">
                  <div>
                    <h2 className="page_title">
                      {campaign.probonoCampaign
                        ? `Somme versée à l'association ${campaign.association?.labelFr ?? "de votre choix"}`
                        : "Rémunération proposée"}
                    </h2>
                    <p>Pour une interview de 30 minutes en visio</p>
                  </div>

                  <div className="title--70 --txt--blue">
                    {campaign.price! / 100}€
                  </div>

                  <div>
                    <button
                      type="button"
                      className="btn--2"
                      onClick={() => setShowCampaignDialog(true)}
                    >
                      J'accepte l'interview
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {showCampaignDialog && (
        <CampaignDialog
          campaign={campaign}
          currentUser={currentUser}
          onClose={() => setShowCampaignDialog(false)}
        />
      )}
    </div>
  );
};

export default CampaignPage;
