import { Link, useLoaderData } from "react-router-dom";
import BackButton from "../../../../components/BackButton";
import CampaignForm from "./CampaignForm";
import { User } from "../../../../services/user/userModel";
import { Campaign } from "../../../../services/campaign/campaignModel";
import { campaignService } from "../../../../services/campaign/campaignService";
import useReload from "../../../../hooks/useReload";
import SubmitButton from "../../../../components/forms/SubmitButton";
import { createRef, useMemo, useState } from "react";
import BusinessAccountPaymentMethodDialog from "./BusinessAccountPaymentMethodDialog";
import { ClientOnly } from "../../../../react-helpers/react";
import CopyToClipboard from "../../../../components/utilities/CopyToClipboard";
import { formatDate } from "../../../../react-helpers/date";
import { toastsWithIntl } from "../../../../services/toastService";
import { useConfirmationWithIntl } from "../../../../components/ConfirmationDialog";
import Icon from "../../../../components/Icon";
import CampaignStatusChip from "./CampaignStatusChip";
import ActivateCampaignDialog from "./ActivateCampaignDialog";
import { Association } from "../../../../services/data/dataModel";

const { updateCampaign } = campaignService();
const { toastSuccess, toastError } = toastsWithIntl(["campaign"]);

const EditCampaignPage = () => {
  const topAnchor = createRef<HTMLDivElement>();

  const reload = useReload();

  const { confirm } = useConfirmationWithIntl(["campaign"]);
  const { currentUser, campaign, associations } = useLoaderData() as {
    currentUser: User;
    campaign: Campaign;
    associations: Association[];
  };

  const [showEditPayment, setShowEditPayment] = useState(false);
  const [showActivate, setShowActivate] = useState(false);

  const currentUserCompletedOnboarding = !!currentUser.completedOnboarding;
  const referentCompletedOnboarding = !!campaign.referent?.completedOnboarding;
  const businessAccountHasCard = useMemo(
    () => !!campaign.businessAccount?.stripeCustomer?.card,
    [campaign],
  );
  const isBusinessAccountCardExpired = useMemo(() => {
    if (!campaign.businessAccount?.stripeCustomer?.card) return true;

    const card = campaign.businessAccount.stripeCustomer.card;
    const today = new Date();
    if (card.exp_year < today.getFullYear()) return true;
    if (
      card.exp_month < today.getMonth() + 1 &&
      card.exp_year == today.getFullYear()
    )
      return true;

    return false;
  }, [campaign]);
  const bookingsCount = useMemo(
    () =>
      campaign.bookingRequests?.filter(
        (bookingRequest) => !!bookingRequest.booking,
      ).length,
    [campaign],
  );

  return (
    <div className="container">
      <div ref={topAnchor}></div>
      <div className="page_head">
        <div>
          <BackButton
            forcedUrl={`/app/pro/${campaign.businessAccountId}/campaigns`}
          />

          <h1 className="page_title">Gestion de la campagne</h1>
        </div>
        <CampaignStatusChip campaign={campaign} />
      </div>
      <div className="page-content page-aside-layout --invert">
        <div className="page_main card">
          <div className="card_body">
            <CampaignForm
              amountVisioBooked={bookingsCount ?? 0}
              associations={associations}
              initialValues={campaign}
              currentUser={currentUser}
              onSubmit={async (values) => {
                await updateCampaign(values).then(
                  () => {
                    toastSuccess("campaign:update.SUCCESS");
                    reload();
                  },
                  () => {
                    toastError("campaign:update.ERROR");
                  },
                );
              }}
            />
          </div>
        </div>
        <div className="page_aside cblocks">
          {(campaign.status === "SUSPENDED" ||
            campaign.status === "ACTIVE") && (
            <>
              {campaign.type === "OPEN" && (
                <ClientOnly>
                  {() => {
                    const publicUrl = `${window.location.origin}/campaigns/${campaign.uuid}`;
                    return (
                      <div className="info --success">
                        <div>
                          Partagez à vos contacts le{" "}
                          <strong>lien de votre campagne</strong>
                        </div>
                        <CopyToClipboard value={publicUrl}>
                          <a
                            className="link--accent --s --truncate"
                            href={publicUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {publicUrl}
                          </a>
                        </CopyToClipboard>
                      </div>
                    );
                  }}
                </ClientOnly>
              )}

              {campaign.status === "SUSPENDED" && (
                <div className="card --bg">
                  <div className="card_body cblocks">
                    <div className="info --warning">
                      Cette campagne est suspendue
                    </div>
                    <SubmitButton
                      type="button"
                      className="btn --s --block"
                      onClick={async () => {
                        await updateCampaign({
                          id: campaign.id,
                          status: "ACTIVE",
                        }).then(
                          () => {
                            toastSuccess("campaign:reactivate.SUCCESS");
                            reload();
                          },
                          () => {
                            toastError("campaign:reactivate.ERROR");
                          },
                        );
                      }}
                    >
                      Réactiver la campagne
                    </SubmitButton>
                  </div>
                </div>
              )}

              {campaign.endDate &&
                (new Date() > new Date(campaign.endDate) ? (
                  <div className="info --error">Campagne expirée</div>
                ) : (
                  <div className="info">
                    La campagne expirera le {formatDate(campaign.endDate)}
                  </div>
                ))}
            </>
          )}
          {["TARGETED", "MANAGED"].includes(campaign.type!) && (
            <>
              {businessAccountHasCard &&
                !isBusinessAccountCardExpired &&
                !campaign.bookingRequests?.length && (
                  <Link className="link --soft --block" to={`contacts/import`}>
                    {" "}
                    <div className="callout">
                      <Icon name="rocket" />
                      <div>
                        <strong>IMPORTEZ</strong>
                        <br />
                        vos premiers contacts
                      </div>
                    </div>
                  </Link>
                )}
              <div className="scorecard">
                <Icon name="users" />
                <div>
                  <div className="scorecard_label">Contacts de la campagne</div>

                  <div className="scorecard_value">
                    {campaign.bookingRequests?.length}
                  </div>
                  {businessAccountHasCard && !isBusinessAccountCardExpired && (
                    <div>
                      {(campaign.bookingRequests?.length ?? 0) > 0 ? (
                        <Link className="link --s" to={`contacts`}>
                          gérer les contacts
                        </Link>
                      ) : (
                        <Link className="link --s" to={`contacts/import`}>
                          Importer des contacts
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          {["CLOSED", "ACTIVE", "SUSPENDED"].includes(campaign.status) && (
            <div className="scorecard">
              <Icon name="calendar" />
              <div>
                <div className="scorecard_label">Nombre de rendez-vous</div>
                <div className="scorecard_value">
                  {bookingsCount ?? 0}
                  <small>{campaign.maxVisio && `/${campaign.maxVisio}`}</small>
                </div>
                {!!bookingsCount && (
                  <Link className="link --s" to={"bookings"}>
                    voir les rendez-vous
                  </Link>
                )}
              </div>
            </div>
          )}

          {!businessAccountHasCard ? (
            <div className="info --error">
              <div>
                Vous devez{" "}
                <button
                  type="button"
                  className="link --s"
                  onClick={() => setShowEditPayment(true)}
                >
                  enregistrer un moyen de paiement
                </button>{" "}
                sur votre compte pro Tisio
              </div>
            </div>
          ) : isBusinessAccountCardExpired ? (
            <div className="info --error">
              Le moyen de paiement enregistré sur votre compte pro Tisio n'est
              plus valide.
              <br />
              <button
                type="button"
                className="link"
                onClick={() => setShowEditPayment(true)}
              >
                Le mettre à jour
              </button>
            </div>
          ) : (
            <></>
            // TODO delete when campaign activation confirmation popup is implemented
            // <div className="info">
            //   <button
            //     type="button"
            //     className="link --s"
            //     onClick={() => setShowEditPayment(true)}
            //   >
            //     Mettre à jour mon moyen de paiement
            //   </button>
            // </div>
          )}
          {campaign.status === "DRAFT" && (
            <div className="cblocks">
              {!referentCompletedOnboarding &&
                campaign.referentId !== currentUser.id && (
                  <div className="info --error">
                    Pour pouvoir activer cette campagne, son référent doit
                    paramétrer son calendrier
                  </div>
                )}
              {!referentCompletedOnboarding &&
                campaign.referentId === currentUser.id && (
                  <div className="info --error">
                    Pour activer cette campagne, vous devez
                    <Link
                      to="/app/my-calendar"
                      className="link --s"
                      target="_blank"
                    >
                      paramétrer votre calendrier
                    </Link>
                  </div>
                )}

              <div className="card --bg">
                <div className="card_body cblocks">
                  <div className="info --warning">
                    Cette campagne est en attente d'activation
                  </div>

                  <SubmitButton
                    type="button"
                    className="btn--2 --block"
                    disabled={
                      !referentCompletedOnboarding ||
                      !currentUserCompletedOnboarding ||
                      !businessAccountHasCard ||
                      isBusinessAccountCardExpired
                    }
                    onClick={() => setShowActivate(true)}
                  >
                    Activer la campagne
                  </SubmitButton>
                </div>
              </div>
            </div>
          )}

          {(!campaign.endDate ||
            !(new Date() > new Date(campaign.endDate))) && (
            <>
              {campaign.status === "ACTIVE" && (
                <SubmitButton
                  type="button"
                  className="btn--warning --block --s"
                  onClick={async () => {
                    await updateCampaign({
                      id: campaign.id,
                      status: "SUSPENDED",
                    }).then(
                      () => {
                        toastSuccess("campaign:suspend.SUCCESS");
                        reload();
                      },
                      () => {
                        toastError("campaign:suspend.ERROR");
                      },
                    );
                  }}
                >
                  Suspendre la campagne
                </SubmitButton>
              )}
            </>
          )}

          {["ACTIVE", "SUSPENDED"].includes(campaign.status) && (
            <SubmitButton
              className="btn--error --s --block"
              type="button"
              onClick={() => {
                confirm(
                  "campaign:close.MESSAGE",
                  () => {
                    return updateCampaign({
                      id: campaign.id,
                      status: "CLOSED",
                    }).then(
                      () => {
                        toastSuccess("campaign:close.SUCCESS");
                        reload();
                      },
                      () => {
                        toastError("campaign:close.ERROR");
                      },
                    );
                  },
                  true,
                  {
                    title: "Confirmer la clôture de la campagne",
                    confirmText: "Clôturer la campagne",
                  },
                );
              }}
            >
              Clôturer définitivement la campagne
            </SubmitButton>
          )}
        </div>
      </div>
      {showActivate && (
        <ActivateCampaignDialog
          campaign={campaign}
          onConfirm={async () => {
            await updateCampaign({
              id: campaign.id,
              status: "ACTIVE",
            }).then(
              () => {
                toastSuccess("campaign:activate.SUCCESS");
                setShowActivate(false);
                reload();
              },
              () => {
                toastError("campaign:activate.ERROR");
              },
            );
          }}
          onUpdatePayment={() => setShowEditPayment(true)}
          onClose={() => setShowActivate(false)}
        />
      )}
      {showEditPayment && (
        <BusinessAccountPaymentMethodDialog
          businessAccount={campaign.businessAccount!}
          onClose={() => setShowEditPayment(false)}
        />
      )}
    </div>
  );
};

export default EditCampaignPage;
