import { Link } from "react-router-dom";
import { IS_DEV } from "../../services/config";
import Logo from "../../assets/img/logo-tisio-white.png";

function SiteFooter() {
  return (
    <div className="footer">
      <div className="footer_content">
        <div className="footer_head">
          <Link to="/" className="logo">
            <img src={Logo} alt="Logo Tisio" />
          </Link>
          <div className="body--30">
            Des visios rémunérées pour
            <br />
            des rendez-vous ultra-qualifiés
          </div>
        </div>
        <div className="lrow --gap--l">
          <div className="footer_section">
            <h4 className="section_title">Tisio</h4>
            <nav>
              {IS_DEV && (
                <Link to="/sx-ui" target="_blank">
                  SxUI
                </Link>
              )}
              <Link to="/how-it-works">Comment ça marche ?</Link>
              <a href="https://www.tisio.fr/business/prospection-commerciale">
                Lancer une campagne de sollicitation
              </a>
              <Link to="/legal-guide">Guide : Statut, Fiscalité & Contrat</Link>
              <Link to="/code-of-conduct">
                Code de conduite d'un profil Tisio
              </Link>
            </nav>
          </div>

          <div className="footer_section">
            <h4 className="section_title">Mon compte</h4>
            <nav>
              <Link to="/login">Connexion</Link>
              <Link to="/register">Inscription</Link>
              <Link to="/app">Mon espace utilisateur</Link>
            </nav>
          </div>

          <div className="footer_section">
            <h4 className="section_title">Aide & contact</h4>
            <nav>
              <a href="https://www.tisio.fr/faq">FAQ</a>
              <Link to="contact">Nous contacter</Link>
            </nav>
          </div>

          <div className="footer_section">
            <h4 className="section_title">À propos</h4>
            <nav>
              <Link to="/legal-mentions">Mentions légales</Link>
              <Link to="/general-conditions-of-use">CGU & CGV</Link>
              <Link to="/privacy">Protection des données</Link>
            </nav>
          </div>
        </div>
        <div className="copyright">© 2024 Tisio</div>
      </div>
    </div>
  );
}
export default SiteFooter;
