import { Link, useLoaderData } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { Campaign } from "../../../services/campaign/campaignModel";
import { BusinessAccount } from "../../../services/businessAccount/businessAccountModel";
import { formatDate } from "../../../react-helpers/date";
import { orderByField, useSearch } from "../../../react-helpers/array";
import { useMemo, useState } from "react";
import CampaignStatusChip from "./campaign/CampaignStatusChip";
import { useTranslation } from "react-i18next";
import { cx } from "../../../react-helpers/css";
import DisplayPrice from "../../../components/DisplayPrice";

const CampaignsListPage = () => {
  const { campaigns, businessAccount } = useLoaderData() as {
    campaigns: Campaign[];
    businessAccount: BusinessAccount;
  };

  const { t } = useTranslation(["campaign"]);

  const sortedCampaigns = useMemo(
    () => campaigns.sort(orderByField("createdAt", "desc")),
    [campaigns],
  );

  const [filterByStatus, setFilterByStatus] = useState<string | null>("ACTIVE");
  const filteredCampaigns = useMemo(() => {
    return sortedCampaigns.filter((c) => {
      return filterByStatus === null || c.status === filterByStatus;
    });
  }, [sortedCampaigns, filterByStatus]);

  const [searchedCampaigns, setSearch] = useSearch(
    filteredCampaigns,
    (campaign) => [
      campaign.name,
      campaign.referent?.firstname,
      campaign.referent?.lastname,
      campaign.referent?.email,
    ],
  );

  return (
    <div className="container">
      <div className="page_head">
        <div>
          <BackButton fallbackUrl="/app/dashboard" />
          <h1 className="page_title">Mes campagnes</h1>
        </div>
      </div>
      <div className="page-content lblocks">
        {!businessAccount.expiredSubscriptions.includes("CAMPAIGN") &&
          !businessAccount.validSubscriptions.includes("CAMPAIGN") && (
            <div className="info --warning">
              L’option « Campagnes » n’est pas activée sur votre compte.
              <br />
              <Link to="/contact">Nous contacter pour souscrire</Link>
            </div>
          )}

        {!businessAccount.validSubscriptions.includes("CAMPAIGN") &&
          businessAccount.expiredSubscriptions.includes("CAMPAIGN") && (
            <div className="info --warning">
              Votre souscription a expiré
              <br />
              <Link to="/contact">Nous contacter pour la renouveler</Link>
            </div>
          )}

        {businessAccount.validSubscriptions.includes("CAMPAIGN") &&
          businessAccount.isCurrentUserAdmin && (
            <Link className="btn" to="new">
              Nouvelle campagne
            </Link>
          )}

        {campaigns.length > 0 && (
          <div className="lblocks">
            <div className="lrow --bottom">
              <div className="--stretched">
                <input
                  className="input"
                  onChange={(ev) => setSearch(ev.target.value)}
                  placeholder="Rechercher une campagne"
                />
              </div>

              <div>
                <label className="field-label">Filter par statut</label>
                <select
                  className="select"
                  value={filterByStatus ?? ""}
                  onChange={(ev) =>
                    setFilterByStatus(
                      ev.target.value.length > 0 ? ev.target.value : null,
                    )
                  }
                >
                  <option value="">Toutes</option>
                  <option value="ACTIVE">
                    {t("campaign:campaign-status-filter.ACTIVE")}
                  </option>
                  <option value="DRAFT">
                    {t("campaign:campaign-status-filter.DRAFT")}
                  </option>
                  <option value="SUSPENDED">
                    {t("campaign:campaign-status-filter.SUSPENDED")}
                  </option>
                  <option value="CLOSED">
                    {t("campaign:campaign-status-filter.CLOSED")}
                  </option>
                </select>
              </div>
            </div>
          </div>
        )}

        <div className="lblocks">
          {searchedCampaigns.length === 0 && (
            <div className="cblocks info">
              {searchedCampaigns.length !== campaigns.length
                ? "Aucune campagne ne correspond à ces critères de recherche"
                : "Aucune campagne"}
            </div>
          )}
          {searchedCampaigns.length > 0 && (
            <div className="table-scroll">
              <table className="table">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Type</th>
                    <th>Mode de diffusion</th>
                    <th />
                    <th>Référent</th>
                    {/* TODO : class to fit without wrapping label */}
                    <th>Montant&nbsp;par&nbsp;visio</th>
                    <th>Date&nbsp;de&nbsp;fin</th>
                    <th>Contacts</th>
                    <th>Rdvs</th>
                    <th>Coût</th>
                    <th>Budget&nbsp;max.</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {searchedCampaigns.map((campaign) => (
                    <tr key={campaign.id}>
                      <td className="--shrink">
                        <strong>{campaign.name}</strong>
                      </td>
                      <td className="--shrink">
                        <span className="tag">
                          {t(`campaign:type.${campaign.type!}`)}
                        </span>
                      </td>
                      <td className="--shrink">
                        {campaign.type !== "OPEN" && (
                          <span
                            className={cx([
                              campaign.type === "MANAGED" && "tag--2",
                              campaign.type === "TARGETED" && "tag",
                            ])}
                          >
                            {t(`campaign:mode.${campaign.type!}`)}
                          </span>
                        )}
                      </td>
                      <td className="--shrink">
                        <CampaignStatusChip campaign={campaign} />
                      </td>
                      <td className="--shrink">
                        {campaign.referent?.firstname}{" "}
                        {campaign.referent?.lastname}
                      </td>
                      <td className="--shrink --txt--center">
                        {campaign.price
                          ? `${(campaign.price / 100).toFixed(2)}€`
                          : "-"}
                      </td>
                      <td className="--shrink">
                        {campaign.endDate && formatDate(campaign.endDate)}
                      </td>
                      <td className="--shrink --txt--center">
                        {campaign.type === "OPEN" ? (
                          "-"
                        ) : (
                          <Link
                            className="link--accent"
                            to={String(campaign.id + "/contacts")}
                          >
                            {campaign.contactsNb ?? "0"}
                          </Link>
                        )}
                      </td>
                      <td className="--shrink --txt--center">
                        <Link
                          className="link--accent"
                          to={String(campaign.id + "/bookings")}
                        >
                          {campaign.bookingsNb ?? "0"}
                          {campaign.maxVisio && (
                            <small className="--txt--pending">
                              /{campaign.maxVisio}
                            </small>
                          )}
                        </Link>
                      </td>
                      <td className="--shrink --txt--center">
                        <DisplayPrice
                          amount={(campaign.bookingsNb ?? 0) * campaign.price!}
                          taxFree
                          currency="EUR"
                        />
                      </td>
                      <td className="--shrink --txt--center">
                        {campaign.maxVisio ? (
                          <DisplayPrice
                            amount={campaign.maxVisio * campaign.price!}
                            taxFree
                            currency="EUR"
                          />
                        ) : (
                          "-"
                        )}
                      </td>

                      <td className="--txt--right">
                        <Link
                          to={String(campaign.id)}
                          className="btn --btn--icon --chevron-right"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CampaignsListPage;
