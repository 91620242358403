import { object, string } from "yup";
import SubmitButton from "../../../components/forms/SubmitButton";
import SxForm from "../../../forms/SxForm";
import ValidationErrors from "../../../forms/ValidationErrors";
import SxField from "../../../forms/fields/SxField";
import {
  BookingRequest,
  BookingRequestAttendanceDeclaration,
} from "../../../services/bookingRequest/bookingRequestModel";
import {
  transformEmptyToNull,
  transformFileToString,
} from "../../../react-helpers/yup";
import { bookingRequestService } from "../../../services/bookingRequest/bookingRequestService";
import { toastsWithIntl } from "../../../services/toastService";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import { useExtClosureBankingDetails } from "../../public/closure/useExtClosureBankingDetails";
import { Association } from "../../../services/data/dataModel";
import { authService } from "../../../services/auth/authService";
import { LONG_TOAST_DURATION_IN_MS } from "../../../services/config";
import { Campaign } from "../../../services/campaign/campaignModel";

const BookingClosureBookingNotHappenedForm = ({
  campaign,
  associations,
  isAlreadyCompleted,
  bookingRequest,
  isCurrentUserSender,
}: {
  campaign?: Campaign;
  associations?: Association[];
  isAlreadyCompleted: boolean;
  bookingRequest: BookingRequest;
  isCurrentUserSender: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["bookingRequest"]);
  const { toastSuccess, toastError } = toastsWithIntl([
    "global",
    "bookingRequest",
    "campaign",
  ]);
  const { updateBookingRequestById } = bookingRequestService();
  const { isLoggedIn } = authService();
  const attendancePossibilities = useMemo(
    () => [
      BookingRequestAttendanceDeclaration.SENDER_WAS_NOT_HERE,
      BookingRequestAttendanceDeclaration.RECIPIENT_WAS_NOT_HERE,
    ],
    [],
  );
  const [needBankDetails, setNeedBankDetails] = useState(false);
  const {
    probono,
    ExtClosureBankingDetails,
    bankingDetailsSchema,
    bankingDetailsInitialValues,
  } = useExtClosureBankingDetails({
    forcedProbono: campaign?.probonoCampaign,
    associationId: campaign?.associationId,
  });

  const validationSchema = useMemo(
    () =>
      object(
        isCurrentUserSender
          ? {
              senderAttendanceDeclaration: string()
                .label("Que s’est-il passé ?")
                .oneOf(attendancePossibilities)
                .required(),
              senderAdminComment: string()
                .label("Commentaire optionnel à destination de l’équipe Tisio")
                .transform(transformEmptyToNull)
                .nullable(),
            }
          : {
              recipientAttendanceDeclaration: string()
                .label("Que s’est-il passé ?")
                .oneOf(attendancePossibilities)
                .required(),
              recipientAdminComment: string()
                .label("Commentaire optionnel à destination de l’équipe Tisio")
                .transform(transformEmptyToNull)
                .nullable(),
            },
      ).concat(needBankDetails ? bankingDetailsSchema : object()),
    [
      attendancePossibilities,
      isCurrentUserSender,
      bankingDetailsSchema,
      needBankDetails,
    ],
  );

  return (
    <SxForm
      initialValues={{
        ...(isAlreadyCompleted
          ? bookingRequest
          : isCurrentUserSender
            ? {
                senderAdminComment: null,
              }
            : {
                recipientAdminComment: null,
              }),
        ...bankingDetailsInitialValues,
      }}
      onSubmit={async (values: Partial<BookingRequest>) => {
        return updateBookingRequestById(
          isLoggedIn() ? bookingRequest.id : bookingRequest.uuid,
          {
            ...bookingRequest,
            ...values,
            ...(isCurrentUserSender
              ? {
                  senderClosureDate: new Date().toISOString(),
                }
              : {
                  recipientClosureDate: new Date().toISOString(),
                }),
            ...(probono
              ? {}
              : {
                  extBankDetails: await transformFileToString(
                    (values as any).extBankDetails,
                  ),
                }),
          },
        ).then(
          () => {
            if (bookingRequest.extEmail) {
              toastSuccess(
                campaign?.probonoCampaign
                  ? "campaign:booking-closure.probono.SUCCESS"
                  : "campaign:booking-closure.SUCCESS",
                {
                  duration: LONG_TOAST_DURATION_IN_MS,
                },
              );
              navigate(`/`);
            } else {
              if (!isCurrentUserSender) {
                toastSuccess(
                  "bookingRequest:recipient-cloture.ONE_WAS_NOT_HERE",
                  {
                    duration: LONG_TOAST_DURATION_IN_MS,
                  },
                );
              } else {
                toastSuccess("bookingRequest:sender-cloture.SUCCESS");
              }
              navigate(`/app/bookings/${bookingRequest.uuid}`);
            }
          },
          () => {
            if (bookingRequest.extEmail) {
              toastError("campaign:booking-closure.ERROR");
            } else {
              toastError("global:GENERIC_ERROR");
            }
          },
        );
      }}
      validationSchema={validationSchema}
      disabled={isAlreadyCompleted}
    >
      <SxField
        name={
          isCurrentUserSender
            ? "senderAttendanceDeclaration"
            : "recipientAttendanceDeclaration"
        }
        as="select"
        hookOnUpdate={(v) =>
          associations &&
          setNeedBankDetails(
            v ===
              (isCurrentUserSender
                ? BookingRequestAttendanceDeclaration.RECIPIENT_WAS_NOT_HERE
                : BookingRequestAttendanceDeclaration.SENDER_WAS_NOT_HERE),
          )
        }
      >
        <option>-</option>
        {attendancePossibilities.map((v) => (
          <option key={v} value={v}>
            {t(
              isCurrentUserSender
                ? `attendance-declaration-sender.${v}`
                : `attendance-declaration-recipient.${v}`,
            )}
          </option>
        ))}
      </SxField>

      <SxField
        name={
          isCurrentUserSender ? "senderAdminComment" : "recipientAdminComment"
        }
        as="textarea"
      />
      <div className="field-tip">
        Votre interlocuteur n’en aura pas connaissance
      </div>

      {needBankDetails && associations && (
        <ExtClosureBankingDetails associations={associations} />
      )}

      {!isAlreadyCompleted && (
        <div className="form_footer --txt--center cblocks">
          <ValidationErrors />
          <SubmitButton className="btn" type="submit">
            Envoyer et clôturer le RDV
          </SubmitButton>
        </div>
      )}
    </SxForm>
  );
};

export default BookingClosureBookingNotHappenedForm;
