import { useNavigate } from "react-router-dom";
import SubmitButton from "../../../components/forms/SubmitButton";
import SxForm from "../../../forms/SxForm";
import ValidationErrors from "../../../forms/ValidationErrors";
import SxField from "../../../forms/fields/SxField";
import {
  BookingRequest,
  BookingRequestAttendanceDeclaration,
} from "../../../services/bookingRequest/bookingRequestModel";
import { toastsWithIntl } from "../../../services/toastService";
import { bookingRequestService } from "../../../services/bookingRequest/bookingRequestService";
import { number, object, string } from "yup";
import {
  transformEmptyToNull,
  transformFileToString,
  transformNumericStringToNumberOrNull,
} from "../../../react-helpers/yup";
import { Association } from "../../../services/data/dataModel";
import { useMemo } from "react";
import { useExtClosureBankingDetails } from "./useExtClosureBankingDetails";
import { LONG_TOAST_DURATION_IN_MS } from "../../../services/config";
import { omitKeys } from "../../../react-helpers/object";
import { Campaign } from "../../../services/campaign/campaignModel";

const ExtClosureBookingHappenedForm = ({
  campaign,
  associations,
  isAlreadyCompleted,
  bookingRequest,
}: {
  campaign?: Campaign;
  associations: Association[];
  isAlreadyCompleted: boolean;
  bookingRequest: BookingRequest;
}) => {
  const navigate = useNavigate();
  const { toastSuccess, toastError } = toastsWithIntl(["campaign"]);
  const { updateBookingRequestById } = bookingRequestService();

  const {
    probono,
    ExtClosureBankingDetails,
    bankingDetailsSchema,
    bankingDetailsInitialValues,
  } = useExtClosureBankingDetails({
    forcedProbono: campaign?.probonoCampaign,
    associationId: campaign?.associationId,
  });

  const validationSchema = useMemo(
    () =>
      object({
        recipientRating: number()
          .transform(transformNumericStringToNumberOrNull)
          .nullable()
          .required(),
        recipientComment: string()
          .label("Laissez un avis à votre interlocuteur")
          .transform(transformEmptyToNull)
          .nullable(),
        recipientAdminComment: string()
          .label("Commentaire optionnel à destination de l’équipe Tisio")
          .transform(transformEmptyToNull)
          .nullable(),
      }).concat(bankingDetailsSchema),
    [bankingDetailsSchema],
  );

  return (
    <SxForm
      initialValues={{
        ...bookingRequest,
        ...bankingDetailsInitialValues,
      }}
      onSubmit={async (values) => {
        return updateBookingRequestById(bookingRequest.uuid, {
          ...bookingRequest,
          ...omitKeys(
            values,
            probono ? ["extIban", "extBic"] : ["recipientAssociationId"],
          ),
          recipientAttendanceDeclaration:
            BookingRequestAttendanceDeclaration.BOTH_WHERE_HERE,
          recipientClosureDate: new Date().toISOString(),
          ...(probono
            ? {}
            : {
                extBankDetails: await transformFileToString(
                  (values as any).extBankDetails,
                ),
              }),
        }).then(
          () => {
            toastSuccess(
              campaign?.probonoCampaign
                ? "campaign:booking-closure.probono.SUCCESS"
                : "campaign:booking-closure.SUCCESS",
              {
                duration: LONG_TOAST_DURATION_IN_MS,
              },
            );
            navigate(`/`);
          },
          () => {
            toastError("campaign:booking-closure.ERROR");
          },
        );
      }}
      validationSchema={validationSchema}
      disabled={isAlreadyCompleted}
    >
      <div className="lblocks">
        <div className="card">
          <div className="card_body --txt--center">
            <h2 className="section_title">Évaluez votre interlocuteur *</h2>
            <div className="lblock star-rating --center --l">
              <SxField
                name={"recipientRating"}
                as="radio"
                radios={[
                  { label: "★", value: "5" },
                  { label: "★", value: "4" },
                  { label: "★", value: "3" },
                  { label: "★", value: "2" },
                  { label: "★", value: "1" },
                ]}
              />
              {/* Values order is reversed because of technical display constraint (direction rtl) */}
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card_body cblocks">
            <SxField name={"recipientComment"} as="textarea" />
            <div>
              <SxField name={"recipientAdminComment"} as="textarea" />
              <div className="field-tip">
                Votre interlocuteur n’en aura pas connaissance
              </div>
            </div>
          </div>
        </div>

        <div className="card --bg">
          <div className="card_body lblocks">
            <h2 className="section_title --txt--center">Votre rémunération</h2>
            <ExtClosureBankingDetails associations={associations} />
          </div>
        </div>

        {!isAlreadyCompleted && (
          <div className="form_footer --txt--center">
            <ValidationErrors />
            <SubmitButton className="btn" type="submit">
              Envoyer et clôturer le RDV
            </SubmitButton>
          </div>
        )}
      </div>
    </SxForm>
  );
};

export default ExtClosureBookingHappenedForm;
