import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { cx } from "../react-helpers/css";

export default function Dialog({
  onClose,
  modal,
  children,
  className,
}: {
  onClose: () => void;
  modal?: boolean;
  children: React.JSX.Element;
  className?: string;
}) {
  const [mounted, setMounted] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setMounted(true));

  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);
  const wrapper = (
    <div className="screen-overlay" onClick={!modal ? onClose : undefined}>
      <div
        className={cx(["popup", className])}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="popup_close">
          {!modal && <button className="icon--close" onClick={onClose} />}
        </div>

        {children}
      </div>
    </div>
  );

  return mounted ? createPortal(wrapper, document.body) : null;
}

// TODO CG/JP : useDialog.showDialog()
