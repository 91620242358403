import ProfileSearch from "../../../components/ProfileSearch";
import HomeFAQSection from "./HomeFAQSection";
import HomeHowItWorksSection from "./HomeHowItWorksSection";
import HomeReinsuranceSection from "./HomeReinsuranceSection";
import HomeWhyTisioSection from "./HomeWhyTisioSection";
import Icon from "../../../components/Icon";
import GifTisioProspection from "../../../assets/img/landings/tisio-prospection.gif";

function HomePage() {
  return (
    <div className="landing">
      <div className="landing_head">
        <div className="container">
          <h1 className="head_title lblock--xl --force-first">
            Des <em>visios rémunérées</em> pour des{" "}
            <span className="--txt-nowrap">rendez-vous</span>{" "}
            <em>ultra-qualifiés</em>
          </h1>
          <h2 className="head_tagline">
            Echangez directement avec le professionnel de votre choix.
            <br />
            Rémunérez-le pour son temps. Ou inversement !
          </h2>
          <ProfileSearch />
        </div>
      </div>
      <HomeWhyTisioSection />

      <HomeHowItWorksSection />
      {/* TODO : create component and mutualize with business landing */}
      <div className="section --light">
        <div className="container">
          <div className="l--img-content">
            <div>
              <img src={GifTisioProspection} alt="" />
            </div>
            <div className="lblocks --l">
              <div className="cblocks">
                <h2 className="section_title">
                  Tisio, un outil puissant de prospection commerciale !
                </h2>
                <p>
                  La seule plateforme qui garantit des échanges avec des
                  prospects
                  <br />
                  <strong>disponibles et à l’écoute.</strong>
                </p>
              </div>

              <div>
                <a
                  href="https://www.tisio.fr/business/prospection-commerciale"
                  className="btn--cta"
                >
                  <span>Ça m'intéresse !</span>{" "}
                  <Icon name="arrow-right" className="--blue" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section --blue --sep" />
      <HomeFAQSection />
      <HomeReinsuranceSection />
    </div>
  );
}

export default HomePage;
