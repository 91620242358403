import { Plan } from "../businessAccount/businessAccountModel";
import { toastsWithIntl } from "../toastService";
import {
  createAssociationRequest,
  createSkillRequest,
  getAssociationsRequest,
  getCompanyActivitiesRequest,
  getCompanyHeadcountsRequest,
  getDeclineReasonsRequest,
  getExpertisesRequest,
  getHierarchyLevelsRequest,
  getLanguagesRequest,
  getPlansRequest,
  getPublicAssociationsRequest,
  getSkillsRequest,
} from "./dataApi";
import {
  Association,
  CompanyActivity,
  CompanyHeadcount,
  ConfigData,
  DeclineReason,
  Expertise,
  HierarchyLevel,
  Language,
  MappedConfigData,
  Skill,
} from "./dataModel";

export const mapConfigDataToAutocompleteOptions = (configData: ConfigData) => {
  const mappedConfigData = {} as MappedConfigData;
  Object.entries(configData).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      mappedConfigData[key as keyof MappedConfigData] = value.map(
        (v: { id: number; labelFr: string }) => ({
          label: v.labelFr,
          value: String(v.id),
        }),
      );
    }
  });
  return mappedConfigData;
};

interface DataService {
  getSkills(): Promise<Skill[]>;
  createSkill(skillName: string): Promise<Skill>;
  getAssociations(): Promise<Association[]>;
  getPublicAssociations(): Promise<Association[]>;
  createAssociation(
    associationName: string,
    iban?: string,
  ): Promise<Association>;
  getExpertises(): Promise<Expertise[]>;
  getCompanyActivities(): Promise<CompanyActivity[]>;
  getCompanyHeadcounts(): Promise<CompanyHeadcount[]>;
  getHierarchyLevels(): Promise<HierarchyLevel[]>;
  getLanguages(): Promise<Language[]>;
  getDeclineReasons(): Promise<DeclineReason[]>;
  getPlans(): Promise<Plan[]>;
}

export const dataService = (): DataService => {
  const { toastError } = toastsWithIntl(["global"]);

  const getSkills: DataService["getSkills"] = () => {
    return getSkillsRequest().catch((e) => {
      toastError("global:GENERIC_ERROR", { id: "data-loading-error" });
      return Promise.reject(e);
    });
  };

  const createSkill: DataService["createSkill"] = (skillName) => {
    return createSkillRequest({ labelFr: skillName }).catch((e) => {
      toastError("global:GENERIC_ERROR", { id: "data-loading-error" });
      return Promise.reject(e);
    });
  };

  const getAssociations: DataService["getAssociations"] = () => {
    return getAssociationsRequest().catch((e) => {
      toastError("global:GENERIC_ERROR", { id: "data-loading-error" });
      return Promise.reject(e);
    });
  };

  const getPublicAssociations: DataService["getPublicAssociations"] = () => {
    return getPublicAssociationsRequest().catch((e) => {
      toastError("global:GENERIC_ERROR", { id: "data-loading-error" });
      return Promise.reject(e);
    });
  };

  const createAssociation: DataService["createAssociation"] = (
    associationName,
    iban,
  ) => {
    return createAssociationRequest({ labelFr: associationName, iban }).catch(
      (e) => {
        toastError("global:GENERIC_ERROR", { id: "data-loading-error" });
        return Promise.reject(e);
      },
    );
  };

  const getExpertises: DataService["getExpertises"] = () => {
    return getExpertisesRequest().catch((e) => {
      toastError("global:GENERIC_ERROR", { id: "data-loading-error" });
      return Promise.reject(e);
    });
  };

  const getCompanyActivities: DataService["getCompanyActivities"] = () => {
    return getCompanyActivitiesRequest().catch((e) => {
      toastError("global:GENERIC_ERROR", { id: "data-loading-error" });
      return Promise.reject(e);
    });
  };

  const getCompanyHeadcounts: DataService["getCompanyHeadcounts"] = () => {
    return getCompanyHeadcountsRequest().catch((e) => {
      toastError("global:GENERIC_ERROR", { id: "data-loading-error" });
      return Promise.reject(e);
    });
  };

  const getHierarchyLevels: DataService["getHierarchyLevels"] = () => {
    return getHierarchyLevelsRequest().catch((e) => {
      toastError("global:GENERIC_ERROR", { id: "data-loading-error" });
      return Promise.reject(e);
    });
  };

  const getLanguages: DataService["getLanguages"] = () => {
    return getLanguagesRequest().catch((e) => {
      toastError("global:GENERIC_ERROR", { id: "data-loading-error" });
      return Promise.reject(e);
    });
  };

  const getDeclineReasons: DataService["getDeclineReasons"] = () => {
    return getDeclineReasonsRequest().catch((e) => {
      toastError("global:GENERIC_ERROR", { id: "data-loading-error" });
      return Promise.reject(e);
    });
  };

  const getPlans: DataService["getPlans"] = () => {
    return getPlansRequest().catch((e) => {
      toastError("global:GENERIC_ERROR", { id: "data-loading-error" });
      return Promise.reject(e);
    });
  };

  return {
    getSkills,
    createSkill,
    getAssociations,
    getPublicAssociations,
    createAssociation,
    getExpertises,
    getCompanyActivities,
    getCompanyHeadcounts,
    getHierarchyLevels,
    getLanguages,
    getDeclineReasons,
    getPlans,
  };
};
