import { downloadFile, readFile } from "../../../../../../react-helpers/files";
import SxFileInput from "../../../../../../forms/fields/SxFileInput";
import { toastsWithIntl } from "../../../../../../services/toastService";
import Icon from "../../../../../../components/Icon";
import { ImportedUser, ContactsOutletContext } from "./ContactsImportDialog";
import chardet from "chardet";
import { parse } from "csv-parse/sync";
import { detectSeparator } from "../../../../../../react-helpers/csv";
import CsvMappingTable from "./CsvMappingTable";
import CsvImportValidation from "./CsvImportValidation";
import { useCallback, useState } from "react";
import { useOutletContext } from "react-router-dom";

export type CsvHeadersMapping = Record<string, keyof ImportedUser | null>;

const CsvImportStep = () => {
  const { campaign, setContacts, schema, validContacts, addValidContacts } =
    useOutletContext<ContactsOutletContext>();

  const template = new Blob(
    ["Prénom;Nom;E-mail\r\nJohn;Doe;example@example.com\r\n"],
    { type: "text/csv;charset=utf-8" },
  );
  const { toastError } = toastsWithIntl(["campaign"]);

  const [headers, setHeaders] = useState<string[]>([]);
  const [records, setRecords] = useState<Record<string, string>[]>([]);
  [];
  const [headersMapping, setHeadersMapping] = useState<
    Record<string, keyof ImportedUser | null>
  >({});

  const readFiles = useCallback(
    (files: (File | string)[]) => {
      try {
        void readFile(files[0] as File, "binary").then((rawCsv) => {
          const bytes = new Uint8Array(rawCsv);
          const encoding = chardet.detect(bytes);
          const decoder = new TextDecoder(encoding ?? undefined);
          const csv = decoder.decode(bytes);
          const cleanedCsv = csv.replace(/\r\n/g, "\n");

          let parsedRecords: Record<string, string>[];
          const delimiter = detectSeparator(cleanedCsv);
          if (!delimiter) {
            toastError("campaign:csv-import.ERROR");
            return;
          }

          try {
            parsedRecords = parse(cleanedCsv, {
              columns: true,
              skipEmptyLines: true,
              trim: true,
              delimiter,
            });
          } catch (err) {
            toastError("campaign:csv-import.ERROR");
            return;
          }
          setRecords(parsedRecords);
          setHeaders(Object.keys(parsedRecords[0]));
        });
      } catch (e) {
        return toastError("campaign:csv-import.ERROR");
      }
    },
    [setHeaders, setRecords, toastError],
  );

  return (
    <div className="lblocks">
      <div className="info --warning">
        <div>
          Importer un fichier CSV contenant vos contacts : une{" "}
          <strong>ligne d'entête</strong>, puis{" "}
          <strong>1 contact par ligne</strong> (Nom, Prénom, Email)
        </div>
        <div>
          <button
            className="link--accent --s --with-icon"
            type="button"
            onClick={() => downloadFile(template, "template.csv")}
          >
            <Icon name="download" />
            télécharger le modèle
          </button>
        </div>
      </div>

      <div className="lblocks">
        <SxFileInput accept="text/csv" onChange={readFiles}></SxFileInput>
      </div>

      {headers.length > 0 && (
        <div className="card lblocks">
          <div className="card_body --txt--center lblocks">
            <CsvMappingTable
              headers={headers}
              headersMapping={headersMapping}
              setHeadersMapping={setHeadersMapping}
              records={records}
            />
            <CsvImportValidation
              campaign={campaign}
              headersMapping={headersMapping}
              setContacts={setContacts}
              validContacts={validContacts}
              addValidContacts={addValidContacts}
              records={records}
              schema={schema}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CsvImportStep;
