import { useState } from "react";
import { cx } from "../../../react-helpers/css";

function FaqHome() {
  const [item, setItem] = useState(0);
  return (
    <div className="faq">
      <div className="cblocks">
        <div className={cx(["faq_item", item === 1 && "--open"])}>
          <button className="faq_question" onClick={() => setItem(1)}>
            Tisio c'est quoi ?
          </button>
          <div className="faq_answer">
            Tisio est une plateforme de visios rémunérées permettant de
            solliciter n'importe quel contact pour un échange professionnel 1:1
            confidentiel en visio.
          </div>
        </div>
        <div className={cx(["faq_item", item === 2 && "--open"])}>
          <button className="faq_question" onClick={() => setItem(2)}>
            À quoi peut me servir Tisio ?
          </button>
          <div className="faq_answer cblocks --s">
            <div>
              À plein de choses ! Par exemple… recueillir des conseils pratiques
              sur un sujet, contacter des prospects, échanger avec un expert,
              envisager une reconversion, solliciter un avis avant de lancer un
              projet, et tout ce que vous pourrez imaginer d’autre !
            </div>
            <div>
              Mais aussi, Tisio est un puissant outil de développement
              commercial B2B. Il permet d’organiser des rendez-vous qualifiés
              avec des prospects intéressés et disponibles, grâce à un système
              de visios rémunérées.
              <br />
              <a
                href="https://www.tisio.fr/business/prospection-commerciale"
                className="link--accent --s"
              >
                En savoir plus sur la prospection avec Tisio
              </a>
            </div>
          </div>
        </div>
        <div className={cx(["faq_item", item === 3 && "--open"])}>
          <button className="faq_question" onClick={() => setItem(3)}>
            Dois-je être professionnel indépendant pour créer un profil Tisio ?
          </button>
          <div className="faq_answer">
            Non ! Tout le monde peut créer son profil sur Tisio. Et tant que les
            gains ne dépassent pas 1.500 € par an, il est possible d’opter pour
            un régime sans statut de travailleur indépendant/ auto-entrepreneur.
            Il n’y a dans ce cas pas de formalité administrative de création
            d’entreprise. Il suffit ensuite de renseigner son RIB (ou le nom de
            l’association de son choix) à l’issue d’une visio pour percevoir sa
            rémunération.
          </div>
        </div>
      </div>
      <div className="cblocks">
        <div className={cx(["faq_item", item === 4 && "--open"])}>
          <button className="faq_question" onClick={() => setItem(4)}>
            Comment s’assurer que mon interlocuteur sera qualitatif ?
          </button>
          <div className="faq_answer">
            En amont, la visio n’a lieu que s’il y a une acceptation mutuelle
            entre un demandeur et son interlocuteur. À l’issue de la visio, un
            système de notation croisé permet de valider la conformité et la
            qualité de l’échange.
          </div>
        </div>
        <div className={cx(["faq_item", item === 5 && "--open"])}>
          <button className="faq_question" onClick={() => setItem(5)}>
            Comment fixer mon tarif ?
          </button>
          <div className="faq_answer">
            Pour définir votre tarif pour 30 minutes, on vous encourage à
            regarder les profils similaires aux vôtres déjà présents sur la
            plateforme. Mais surtout, fixez un prix que vous estimez être la
            valeur de vos expériences et compétences professionnelles.
          </div>
        </div>
        <div className={cx(["faq_item", item === 6 && "--open"])}>
          <button className="faq_question" onClick={() => setItem(6)}>
            À quel moment dois-je payer ?
          </button>
          <div className="faq_answer">
            Pour créer son profil Tisio, c'est gratuit ! Pour solliciter
            quelqu'un, une empreinte de carte bancaire vous sera demandée au
            moment de la sollicitation. Le montant de l'échange sera débité
            qu'une fois la visio confirmée par votre interlocuteur.
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaqHome;
