import { parse } from "csv-parse/sync";

const separators = ";|\t,".split("");
export function detectSeparator(csv: string): string | null {
  return (
    separators.filter((delimiter) => {
      let isValid = true;
      // csv-parse throw error by default
      // if the number of columns is inconsistent between lines
      try {
        const rows = parse(csv, {
          delimiter,
          skipEmptyLines: true,
        }) as string[][];
        isValid = rows.some((row) => row.length > 1);
      } catch (e) {
        isValid = false;
      }
      return isValid;
    })[0] ?? null
  );
}
