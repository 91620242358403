// import { Link } from "react-router-dom";
import { Link } from "react-router-dom";
import ImgNewClients from "../../../assets/img/landings/new-clients.png";
import ImgPartners from "../../../assets/img/landings/partners.png";

function HomeHowItWorksSection() {
  return (
    <div className="section --blue --bg-2">
      <h2 className="section_title --txt--center">Comment ça marche ?</h2>
      <div className="lp_overlapping-blocks lblock">
        <div>
          <div className="cblocks">
            <h2 className="paragraph_title">Je souhaite échanger</h2>
            <div className="lblocks --l">
              <div className="cblocks">
                <p>
                  <strong>1.</strong> J’exprime mon besoin : l’objet de ma
                  demande et ce que j’attends de l’échange
                </p>
                <p>
                  <strong>2.</strong> Je sélectionne les profils que je souhaite
                  solliciter
                </p>
                <p>
                  <strong>3.</strong> Je réalise les visios avec les profils
                  ayant accepté ma demande
                </p>
                <p>
                  <strong>4.</strong> Je les rémunère pour le temps consacré
                </p>
              </div>
              <a
                href="https://www.tisio.fr/business/prospection-commerciale"
                className="btn--2 --block"
              >
                Lancer une campagne de sollicitations
              </a>
            </div>
          </div>
          <img src={ImgPartners} alt="" />
        </div>
        <div>
          <img src={ImgNewClients} alt="" />
          <div className="lblocks --l">
            <div className="cblocks">
              <h2 className="paragraph_title">Je partage mes connaissances</h2>
              <div className="cblocks">
                <p>
                  <strong>1.</strong> Je renseigne mes compétences et mes
                  disponibilités
                </p>
                <p>
                  <strong>2.</strong> Je sélectionne les sollicitations que je
                  reçois
                </p>
                <p>
                  <strong>3.</strong> Je réalise les visios avec les personnes
                  m’ayant sollicité
                </p>
                <p>
                  <strong>4.</strong> Je suis rémunéré ou j’en fais bénéficier
                  l’association de mon choix
                </p>
              </div>
            </div>
            <Link className="btn--light --block" to="/register">
              Créer mon profil
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeHowItWorksSection;
