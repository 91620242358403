import { authService } from "../auth/authService";
import { BookingRequest } from "../bookingRequest/bookingRequestModel";
import { BusinessAccount } from "../businessAccount/businessAccountModel";
import { toastsWithIntl } from "../toastService";
import { User } from "../user/userModel";
import {
  addContactsToCampaignRequest,
  createBookingRequestRequest,
  createCampaignRequest,
  getCampaignByIdRequest,
  getPublicCampaignByUuidRequest,
  getCampaignsByUserAndBaIdRequest,
  updateCampaignRequest,
  archiveCampaignBookingRequestRequest,
  unarchiveCampaignBookingRequestRequest,
} from "./campaignApi";
import { Campaign, CampaignContactStatus } from "./campaignModel";

interface CampaignService {
  getCampaign(
    campaignUuid: Campaign["id"] | Campaign["uuid"],
  ): Promise<Campaign>;
  getPublicCampaign(campaignUuid: string): Promise<Campaign>;
  createCampaign(campaign: Campaign): Promise<Campaign>;
  updateCampaign(
    campaign: Partial<Campaign> & Pick<Campaign, "id">,
  ): Promise<Campaign>;
  getAllCampaigns(
    businessAccountId: BusinessAccount["id"],
  ): Promise<Campaign[]>;
  createBookingRequest(
    campaignUuid: Campaign["uuid"],
    externalUser: Pick<
      BookingRequest,
      "extFirstname" | "extLastname" | "extConsent" | "extEmail"
    >,
  ): Promise<BookingRequest>;
  addContactsToCampaign(
    campaignId: Campaign["id"],
    contacts: Pick<User, "firstname" | "lastname" | "email">[],
    sendMail: "send" | "dontSend",
  ): Promise<Campaign>;
  archiveCampaignBookingRequest(
    campaignId: Campaign["id"],
    bookingRequestId: BookingRequest["id"],
  ): Promise<void>;
  unarchiveCampaignBookingRequest(
    campaignId: Campaign["id"],
    bookingRequestId: BookingRequest["id"],
  ): Promise<void>;
}

const { getLoggedUserId } = authService();

export function campaignService(): CampaignService {
  const { toastError } = toastsWithIntl(["global", "campaign"]);

  const getAllCampaigns: CampaignService["getAllCampaigns"] = (
    businessAccountId,
  ) => {
    return getCampaignsByUserAndBaIdRequest(
      getLoggedUserId()!,
      businessAccountId,
    );
  };

  const getCampaign: CampaignService["getCampaign"] = (campaignId) => {
    return getCampaignByIdRequest(campaignId);
  };

  const getPublicCampaign: CampaignService["getPublicCampaign"] = (
    campaignUuid,
  ) => {
    return getPublicCampaignByUuidRequest(campaignUuid);
  };

  const updateCampaign: CampaignService["updateCampaign"] = (campaign) => {
    return updateCampaignRequest(campaign);
  };

  const createCampaign: CampaignService["createCampaign"] = (campaign) => {
    return createCampaignRequest(campaign);
  };

  const createBookingRequest: CampaignService["createBookingRequest"] = (
    campaignUuidd,
    externalUser,
  ) => {
    return createBookingRequestRequest(campaignUuidd, externalUser).catch(
      (error) => {
        if (error.response?.status === 400) {
          const message = error.response?.data.message;
          if (message.toLowerCase().includes("max visio already reached")) {
            toastError("campaign:ERROR_MAX_VISIO", {
              id: "max-visio-error",
            });
          } else if (
            message
              .toLowerCase()
              .includes(
                "create public booking request for a private campaign id",
              )
          ) {
            toastError("campaign:ERROR_PRIVATE_CAMPAIGN", {
              id: "br-error",
            });
          } else {
            toastError("global:GENERIC_ERROR", {
              id: "generic-error",
            });
          }
        } else {
          toastError("global:GENERIC_ERROR", {
            id: "generic-error",
          });
        }
      },
    );
  };

  const addContactsToCampaign: CampaignService["addContactsToCampaign"] = (
    campaignId,
    contacts,
    sendMail,
  ) => {
    return addContactsToCampaignRequest(campaignId, contacts, sendMail);
  };

  const archiveCampaignBookingRequest: CampaignService["archiveCampaignBookingRequest"] =
    (campaignId, bookingRequestId) => {
      return archiveCampaignBookingRequestRequest(
        campaignId,
        bookingRequestId,
      ).catch((err) => {
        toastError("global:GENERIC_ERROR", {
          id: "generic-error",
        });
        return Promise.reject(err);
      });
    };

  const unarchiveCampaignBookingRequest: CampaignService["unarchiveCampaignBookingRequest"] =
    (campaignId, bookingRequestId) => {
      return unarchiveCampaignBookingRequestRequest(
        campaignId,
        bookingRequestId,
      ).catch((err) => {
        toastError("global:GENERIC_ERROR", {
          id: "generic-error",
        });
        return Promise.reject(err);
      });
    };

  return {
    getAllCampaigns,
    getCampaign,
    getPublicCampaign,
    updateCampaign,
    createCampaign,
    createBookingRequest,
    addContactsToCampaign,
    archiveCampaignBookingRequest,
    unarchiveCampaignBookingRequest,
  };
}

export function isUserAdminOfCampaign(user: User, campaign?: Campaign) {
  return (
    user.businessAccounts?.some(
      (ba) =>
        ba.BusinessAccountUser?.admin && campaign?.businessAccountId === ba.id,
    ) ?? null
  );
}

export function mapBookingRequestToContactStatus(
  bookingRequest: BookingRequest,
): CampaignContactStatus {
  if (bookingRequest.status === "BOOKED" || bookingRequest.status === "CLOSED")
    return "ACCEPTED";
  else if (bookingRequest.status === "DECLINED") return "DECLINED";
  else if (bookingRequest.requestMailSent) return "SENT";
  else return "WAITING";
}
