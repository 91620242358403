import { useTranslation } from "react-i18next";
import { ImportedUser } from "./ContactsImportDialog";
import { CsvHeadersMapping } from "./CsvImportStep";

interface Props {
  headers: string[];
  headersMapping: CsvHeadersMapping;
  setHeadersMapping: (
    val: (prev: CsvHeadersMapping) => CsvHeadersMapping,
  ) => void;
  records: Record<string, string>[];
}

const CsvMappingTable = ({
  headers,
  headersMapping,
  setHeadersMapping,
  records,
}: Props) => {
  const { t } = useTranslation(["campaign"]);

  return (
    <table className="table">
      <thead>
        <tr>
          <th className="--txt-nowrap">Nom de la colonne</th>
          <th>Type de la colonne</th>
          <th>Exemples</th>
        </tr>
      </thead>
      <tbody>
        {headers.map((header) => (
          <tr key={header}>
            <td className="--shrink">
              <strong>{header}</strong>
            </td>
            <td>
              <select
                className="select"
                value={
                  Object.entries(headersMapping).find(
                    ([, value]) => value === header,
                  )?.[0] ?? ""
                }
                onChange={(event) => {
                  const mappedKey =
                    event.target.value === "" ? null : event.target.value;
                  setHeadersMapping((prev) => {
                    // Reset previous mapping
                    const newMapping = Object.fromEntries(
                      Object.entries(prev).map(([userKey, value]) => [
                        userKey,
                        value === header ? null : value,
                      ]),
                    );
                    if (mappedKey) newMapping[mappedKey] = header as any;
                    return newMapping;
                  });
                }}
              >
                <option value="">Ignorer la colonne</option>
                {["firstname", "lastname", "email"].map(
                  (mappedHeader, index) => (
                    <option
                      key={`${mappedHeader}-${index}`}
                      value={mappedHeader}
                    >
                      {t(
                        `campaign:map-csv-header.${mappedHeader.toUpperCase() as Uppercase<keyof Omit<ImportedUser, "id">>}`,
                      )}
                    </option>
                  ),
                )}
              </select>
            </td>
            <td>
              {records.slice(0, 3).map((record, index) => (
                <div key={`${record[header]}-${index}`}>{record[header]}</div>
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CsvMappingTable;
