import {
  Link,
  NavLink,
  Outlet,
  useLoaderData,
  useMatch,
} from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import { Campaign } from "../../../../../services/campaign/campaignModel";
import Tabs from "../../../../../components/Tabs";
import { useMemo } from "react";
import Icon from "../../../../../components/Icon";

const CampaignContactsPage = () => {
  const { campaign } = useLoaderData() as {
    campaign: Campaign;
  };

  const archivedBookingRequestsLength = useMemo(
    () =>
      campaign.bookingRequests!.filter((br) => br.campaignArchivedDate !== null)
        .length,
    [campaign.bookingRequests],
  );

  const isArchived =
    useMatch("/app/pro/:proId/campaigns/:campaignId/contacts/archived") !==
    null;

  return (
    <div className="container">
      <div className="page_head">
        <div>
          <BackButton
            forcedUrl={`/app/pro/${campaign.businessAccountId}/campaigns/${campaign.id}`}
          />
          <h1 className="page_title">Contacts de la campagne</h1>
        </div>
        <Link className="btn --s" to={`import`}>
          <Icon name="plus" />
          Importer
        </Link>
      </div>
      {archivedBookingRequestsLength > 0 && (
        <Tabs className="cblock">
          <NavLink className="tab_item" to={`.`} end={isArchived}>
            Contacts actifs
          </NavLink>
          <NavLink className="tab_item" to={`archived`} end>
            Contacts archivés
          </NavLink>
        </Tabs>
      )}
      <div className="page-content lblocks">
        <Outlet context={{ campaign }} />
      </div>
    </div>
  );
};

export default CampaignContactsPage;
