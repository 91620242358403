import { Link } from "react-router-dom";
import { useLogout } from "../../services/auth/authService";
import useReload from "../../hooks/useReload";
import Logo from "../../assets/img/logo-tisio-fluo.png";
import { User } from "../../services/user/userModel";
import ProfilePic from "../profileDisplay/ProfilePic";
import { cx } from "../../react-helpers/css";

function Header({
  onOpenMenu,
  area,
  loggedUser,
}: {
  onOpenMenu?: () => void;
  area: "public" | "private";
  loggedUser?: User | null;
}) {
  const logout = useLogout();
  const reload = useReload();
  return (
    <div className={cx(["header", `--${area}`])}>
      <div className="header_content">
        <Link to="/" className="logo">
          <img src={Logo} alt="Logo Tisio" />
        </Link>
        <nav className="header_nav">
          <a
            href="https://www.tisio.fr/business/prospection-commerciale"
            className="lp_header_cta btn--soft --s"
          >
            Lancer une campagne de sollicitations
          </a>
          <Link to="/profiles-list" className="lp_header_cta btn--soft --s">
            Recherche un profil
          </Link>
          {!loggedUser && (
            <>
              <Link className="btn--2 --s" to="/register">
                Créer mon compte
              </Link>
              <Link className="btn --s" to="/login">
                Me connecter
              </Link>
            </>
          )}
          {loggedUser && (
            <>
              {area === "public" && (
                <Link className="link" to="/app">
                  <div className="ui-row --center--v">
                    <ProfilePic src={loggedUser?.profilePicture} />
                    <span className="body--20">
                      {loggedUser?.firstname} {loggedUser?.lastname?.[0] + "."}
                    </span>
                  </div>
                </Link>
              )}

              <button
                className="icon--logout --blue"
                onClick={() => {
                  logout().then(
                    () => {
                      reload();
                    },
                    () => {
                      //do nothing
                    },
                  );
                }}
              />
            </>
          )}
        </nav>
        <button
          className="toggle-sidebar-btn icon--menu"
          onClick={onOpenMenu}
        />
      </div>
    </div>
  );
}
export default Header;
