import axios from "axios";
import { BASE_URL } from "./config";
import loggerBuilder from "./logger";
import { errorReportingRequest } from "./support/supportApi";
import {
  nativeGetLoggedUser,
  nativeSetLoggedUser,
} from "./auth/authNativeInterface";

const logger = loggerBuilder("http-requests");

export const baseAPI = axios.create({
  baseURL: BASE_URL,
  xsrfCookieName: "XSRF-TOKEN",
  url: "/",
  headers: {},
  //TODO : find why the timeout is so long for the getBookingProfile request
  timeout: 15000,
  withCredentials: false,
});

export function initializeBaseAPI() {
  baseAPI.interceptors.request.clear();
  baseAPI.interceptors.response.clear();

  const storedUser = nativeGetLoggedUser();
  if (storedUser) {
    baseAPI.defaults.headers.Authorization = `Bearer ${storedUser.xsrfToken}`;
  } else {
    baseAPI.defaults.headers.Authorization = "";
  }

  baseAPI.interceptors.request.use(
    (request) => {
      (request as any).startupTime = Date.now();
      return request;
    },
    (error) => {
      logger.debug("REQUEST FAILED", error.config.url, error.data);
      return Promise.reject(error);
    },
  );

  baseAPI.interceptors.response.use(
    (response) => {
      logger.debug(
        `${response.config.method?.toUpperCase()} ${response.status} ${response.config.url}?${Object.entries(
          response.config.params ?? {},
        )
          .map(([k, v]) => `${k}=${String(v)}`)
          .join("&")} executed in ${
          Date.now() - (response.config as any).startupTime
        }ms`,
        response.data,
      );
      return response;
    },
    (error) => {
      logger.debug("RESPONSE FAILED", error.config.url, error.data);
      return Promise.reject(error);
    },
  );

  baseAPI.interceptors.response.use(
    (res) => res,
    (error) => {
      if (error?.response?.status === 401) {
        logger.info(
          "User will be disconnected because of a 401 response of the API",
        );
        nativeSetLoggedUser(null);
        delete baseAPI.defaults.headers.Authorization;

        logger.debug("User is disconnected");
      }
      if (!import.meta.env.SSR) {
        const storedUser = nativeGetLoggedUser();

        return errorReportingRequest({
          errorStatus: error.response?.status as number,
          errorMessage: error.response?.data.message as string,
          userAgent: navigator.userAgent,
          userId: storedUser ? storedUser.id : null,
          frontUrl: window.location.href,
          metadata: {
            request: {
              headers: error.config.headers,
              url: error.config.url,
              method: error.config.method.toUpperCase(),
            },
            response: {
              headers: error.response?.headers,
              body: error.response?.data,
              userAgent: navigator.userAgent,
            },
          },
        }).then(
          () => Promise.reject(error),
          () => Promise.reject(error),
        );
      }
      return Promise.reject(error);
    },
  );
}

initializeBaseAPI();
